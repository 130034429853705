jQuery.fn.moveToUnranked = function() {
  var weightId = $(this).attr('weight_id'),
      clonedContent = $(this).clone();

  clonedContent.find('.remove').hide();
  clonedContent.find('input').val('0');
  clonedContent.removeClass('bordered');

  $("ol.unranked li[weight_id=" + weightId + "]").replaceWith(clonedContent).removeClass('selected');
  $(this).find('.option-content').html('');
  $(this).removeAttr('weight_id').addClass('bordered ui-sortable-handle');
};

setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
  if(disableFileLoading('weights')) return;

  $("#weight_form ol.ranked:not(.disabled)").sortable({ containment: "#weight_form" });
  $("#weight_form ol.mobile-sortable-options:not(.disabled)").sortable({ containment: "#weight_form" });

  $('body').on("keyup click", ".weight-list .unranked:not(.disabled) li", function(e) {
    if ((e.key === ' ' || e.type === 'click') && !$(this).hasClass('selected')) {
      var target = $(".weight-list .ranked li:not([weight_id]):first"),
          allNextOptions = $(this).nextAll('li.hand'),
          nextOption = allNextOptions[0];

      if (target.length) {
        var clonedContent = $(this).clone();
        clonedContent.find(".remove").show();
        clonedContent.addClass("bordered");

        target.replaceWith(clonedContent);
        $(this).addClass('selected');
        $(this).addClass('bordered').find('.option-content').text('');
      }

      if (nextOption) {
        $(nextOption).trigger('focus');
      } else {
        $('.weight-list .unranked .li:first').trigger('focus');
      }
    }
  });

  // Hide the unrank control
  jQuery("li.kra_option.hand span.remove").each(function() {
    jQuery(this).hide()
  });
  
  jQuery("#weight_submit").click(function() {
    var ranked_options = jQuery("ol.ranked li")
    if (ranked_options.length > 2) {
      ranked_options.each(function(index) {
        var input = jQuery(this).find("input");
        input.val(index+1);
      });
    } else {
      $.facebox($('#kra_options_error').html());
      return false
    }
  });

  $('body').on("click", "li.kra_option .remove", function(event) {
    // Get the jQuery object representation of the selected item
    $(this).parents('li').moveToUnranked();
  });
});
