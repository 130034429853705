global.formHelper = {};

formHelper.setValueToField = function(source, targetSelector) {
  $(targetSelector + ':visible').val(source.value);
};

formHelper.defaultAjaxErrorHandler = function(response) {
  var parsedResponse;

  if (response.responseText) {
    try {
      parsedResponse = JSON.parse(response.responseText);
    } catch (e) {
      console.log(e)
    }
  }

  if (response.status === 403 && parsedResponse && !parsedResponse.logged_in) {
    window.location.href = '/logout?unexpected_logout=true';
  } else if (response.status === 500) {
    window.location.reload();
  }
}

function linkTargetsAnchorOnSamePage(link) {
  let href = link.getAttribute('href');

  if (href.charAt(0) === '#') { return true; }

  if (href.match(new RegExp(`^${window.location.toString().replace(/#.*/, '')}#`))) {
    return true;
  } else if (href.match(new RegExp(`^${window.location.pathname}#`))) {
    return true;
  }

  return false;
}

setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
  $(document).on('turbo:click', event => {
    if (linkTargetsAnchorOnSamePage(event.target)) {
      event.preventDefault();
    }
  });

  if (window.location.hash) {
    let $element = $(`a[name="${window.location.hash.substring(1)}"]`);
    let offset = $element.offset();

    if (offset) {
      $('html, body').scrollTop(offset.top);
    }
  }
});

$(function() {
  $.ajaxSetup({
    beforeSend: (xhr, settings) => {
      if (mdFormHelper && settings.isSectionActive !== false) {
        mdFormHelper.showSpinner();
      }

      let token = $('meta[name="csrf-token"]').attr('content');
      xhr.setRequestHeader('X-CSRF-Token', token);
    },

    complete: () => {
      if (mdFormHelper) { mdFormHelper.hideSpinner(); }
    },

    error: formHelper.defaultAjaxErrorHandler
  });
});
