global.setSelect2Values = (selectElement, values) => {
  const name = selectElement.prop('name');
  $(`[name='${name}']`).val(values).trigger('change');
};

var formatOption = (option) => {
  if (!option.id) {
    return option.text;
  }

  var isGroupTitle = $(option.element).hasClass('select2-group-title');

  if (isGroupTitle) {
    return $(`<span class='select2-option-group-title'>${option.text}</span>`);
  } else {
    return option.text;
  }
};

global.mdSelect2 = (selectors, options = {}) => {
  $(selectors).each((_, select) => {
    options.maximumSelectionLength = select.dataset.maxlength;

    options.templateResult = formatOption;

    if (select.dataset.maxlengthmessage) {
      options.language = {
        maximumSelected: function() {
          return select.dataset.maxlengthmessage
        }
      }
    }

    $(select)
      .select2(options)
      .on('select2:open', function (e) {
        $(e.target).addClass('filled');
      })
      .on('select2:close', function (e) {
        var select = $(e.target);

        if (!select.val()) {
          $(e.target).removeClass('filled');
        }
      });
  })
};

setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
  $('.select2-container').remove();
  $('.select2:not(.select2-empty-all)').select2({ closeOnSelect: false });
  $('.select2-empty-all').select2({ multiple: true, closeOnSelect: false });

  $('.select2-empty-all').on('select2:select', function (event) {
    const selectElement = $(this);
    const defaultAll = selectElement.attr('default_all');
    const selectedValues = selectElement.val() || [];
    const options = selectElement.find('option');
    const selectedOptions = options.filter(':selected');
    const areAllOptionsSelected = options.length > 1 && selectedOptions.length >= options.length - 1;
    const currentOptionId = event.params && event.params.data.id;

    if (currentOptionId === '' || areAllOptionsSelected || !selectedValues.length && defaultAll) {
      setSelect2Values(selectElement, '');
    } else if ( selectedValues.length) {
      values = selectedValues.filter(value => value !== '');
      setSelect2Values(selectElement, values);
    }
  }).trigger('select2:select');

  $('.select2-empty-all').on('select2:unselect', function (event) {
    const selectElement = $(this);
    const name = selectElement.prop('name');
    const values = (selectElement.val() || []).filter(value => value !== '');

    $(`[name='${name}']`).val(values).trigger('change');
  });
})
