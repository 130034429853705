global.sortableOptionsHandler = {};

sortableOptionsHandler.dropItem = function(target, draggable, options) {
  if (!target.text().trim()) {
    target.html(draggable.html());

    var parent = draggable.parent();
    draggable.remove();
    parent.remove();
    options.createCallback(target, draggable);
  }
};

sortableOptionsHandler.addPlaceholder = function(item) {
  $(item).parent().addClass('bordered');
  $(item).addClass('z-1');
};

sortableOptionsHandler.keyboardSorting = function(e, el, updateCallback) {
  if(e.key === 'ArrowUp') {
    $(el).insertBefore($(el).prev());
    updateCallback();
    e.preventDefault();
  }

  if(e.key === 'ArrowDown') {
    $(el).insertAfter($(el).next());
    updateCallback();
    e.preventDefault();
  }
};

sortableOptionsHandler.init = function (selector, options) {
  $(selector + " .sortable").sortable({
    placeholder: "placeholder",
    connectWith: ".ranked.options-list",
    // https://api.jqueryui.com/1.12/sortable/#option-helper
    helper: options.helper || "original",
    update: options.updateCallback
  });

  var mobileOptionsBlockSelector = selector + " .mobile-sortable-options",
      mobileOptionsSelector = mobileOptionsBlockSelector + " li";

  $(mobileOptionsBlockSelector).sortable({
    placeholder: "placeholder",
    update: function (_e, ui) {
      options.updateMobileCallback(ui.item, mobileOptionsSelector);
    }
  });

  $(selector + ' .mobile-sortable-options input:radio').on('change', function() {
    if ($(this).is(':checked')) {
      options.createMobileCallback($(this));
    }
  });

  $(selector + " .draggable .option-content").draggable({
      revertDuration: 150,
      revert: function() {
        var item = $(this),
            isSelected = item.hasClass('selected');

        if (isSelected) {
          item.parent().addClass('bordered');
        } else {
          setTimeout(function() {
            item.parent().removeClass('bordered');
          }, 150);
        }

        return !isSelected;
      },
      start: function() {
        sortableOptionsHandler.addPlaceholder(this);
      },
  });

  $(selector + " .sortable .option-content").droppable({
    accept: selector + ' .draggable .option-content'
  });

  $(selector + " .sortable .option-content").on('drop', function(_e, item) {
    sortableOptionsHandler.dropItem($(this), item.draggable, options);
  });

  // Accessibility

  $(selector + ' .draggable .option-content').on('click', function(e) {
    if (!e.target.hasAttribute('tooltip')) {
      var target = $(selector + " .sortable .option-content:not([option_id]):first"),
          allNextOptions = $(this).parents('li').nextAll('li'),
          nextOption = allNextOptions.filter(function(_i, li) { return $(li).find('.option-content').length; })[0];

      if (target.length) {
        sortableOptionsHandler.addPlaceholder(this);
        sortableOptionsHandler.dropItem(target, $(this), options);
      }

      if (nextOption) {
        $(nextOption).find('.option-content').trigger('focus');
      } else {
        $(selector + ' .draggable .option-content:first').trigger('focus');
      }
    }
  });

  $(selector + " .sortable li").on('keydown', function(e) {
    var el = this;

    sortableOptionsHandler.keyboardSorting(e, el, function() {
      options.updateCallback();
      $(el).trigger('focus');
    });
  });

  $(mobileOptionsSelector).on('keydown', function(e) {
    var el = this;

    sortableOptionsHandler.keyboardSorting(e, el, function() {
      options.updateMobileCallback(el, mobileOptionsSelector);
      $(el).find('label').trigger('focus');
    });
  });
};
