global.sidenav = {};

sidenav.open = function () {
  $("#sidenav").addClass("opened");
  $("body").addClass("tw-overflow-hidden");

  overlay.show();
};

sidenav.close = function () {
  $("#sidenav").removeClass("opened");
  overlay.hide();
  $("#sidenav_content").empty();
  $("body").removeClass("tw-overflow-hidden");
};
