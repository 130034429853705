function searchLoadStates() {
  var country_id = jQuery("#search_preferred_location_country option:selected").val();
  var is_usa = (jQuery("#search_preferred_location_country option:selected").html() == "United States of America");

  if (country_id && country_id != "All") {
    jQuery.get("/countries/" + country_id + "/states/", {search: true}, function(options){
      jQuery("#search_preferred_location_state").html(options);
      var state_id = jQuery("#search_preferred_location_state option:first").val();
  		searchLoadCities(country_id, state_id);
  	});
  }

  if (is_usa == true) {
    jQuery("#preferred_location .state").css('display', 'inline-block');
    jQuery("#search_preferred_location_state").show();
  } else {
    jQuery("#preferred_location .state").hide();
    jQuery("#search_preferred_location_state").hide();
  }
}

global.loadWorldCities = function (country_id, element_id, data){
  if (!country_id) return;
  var data = data || {search: false}
  $.ajax({
    type: "GET",
    url: "/countries/" + country_id + "/world_cities",
    data: data,
    success: function(options) {
      if(options && options.html ) $("#" + element_id).html(options.html);
    }
  });
}

function searchLoadCities(country_id, state_id) {
  var country_id = jQuery("#search_preferred_location_country option:selected").val();
  var state_id = jQuery("#search_preferred_location_state option:selected").val() || "All";
  var without_city_state_id = jQuery("#without_city_state_id").val();
  if (state_id != without_city_state_id){
    jQuery(".city").show();
    if (state_id != "All") {
      jQuery.get("/countries/" + country_id + "/states/" + state_id + "/cities", {search: true}, function(response) {
        jQuery("#search_preferred_location_city").html(response);
      });
    }
    else {
      loadWorldCities(country_id, "search_preferred_location_city", {search: true});
    }
  }else {
    jQuery(".city").hide();
    jQuery("#search_preferred_location_city").val("");
  }
};

setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
  $("#search_preferred_location_country").on("change", function () {
    jQuery("#search_preferred_location_other").val("");
    searchLoadStates();
  });

  $("#search_preferred_location_state").on("change", searchLoadCities);

  $("#search_preferred_location_city").on("change", function() {
    if (jQuery(this).val() != "Other") {
      jQuery("#search_preferred_location_other").val("");
    }
  });

  $("#search_preferred_location_other").on("click", function() {
    jQuery("#search_preferred_location_city").val("Other");
  });
});
