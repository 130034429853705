global.PopupDecorator = {
  maxContentHeight: 380,
  faceboxPadding: 105,
  maxContentWidth: 615,

  resizeFixedPopup: function(){
    var facebox = $("#facebox");
    facebox.css('visibility', 'hidden');

    setTimeout(function(){
      var facebox = $("#facebox"),
          fixedSizePopup = $("#facebox .fixed-size-popup");

      if (!facebox.find(".terms-main-content").length && !fixedSizePopup.length){
        var content = facebox.find(".content");
        var notresizable = facebox.find(".notresizable-content")
        if (notresizable.length == 0){
          if (content.width() > PopupDecorator.maxContentWidth){
            content.width(PopupDecorator.maxContentWidth);
            content.css("overflow-x", "auto");
          }
        }else {
          content.css("overflow-x", "hidden");
        }
        if (content.height() > PopupDecorator.maxContentHeight){
          content.height(PopupDecorator.maxContentHeight);
          content.css("overflow-y", "auto");

          $(document).bind('reveal.facebox', function() {
            content.height("auto");
          });
        }
        content.scrollTop(0);
      };
      facebox.css('visibility', 'visible');
    }, 500);
  },

  fixPopupPosition: function(){
    var facebox = $("#facebox:visible"), headerHeight, windowHeight;
    var maxPopupHeight = PopupDecorator.maxContentHeight + PopupDecorator.faceboxPadding;

    if(facebox.find(".fixed-facebox").length != 0){
      headerHeight = $("#header").height() - 35;
      windowHeight = $(window.top).height();
      var top = (windowHeight - maxPopupHeight) / 2;
      top = (top >= headerHeight) ? PopupDecorator.relativePosition(top) : PopupDecorator.relativePosition(headerHeight);
      facebox.css({position: 'fixed', top: top});
    }
  },

  relativePosition: function(value){
    return (value * 100 / $(window.top).height()) + "%"
  }
}
