function showEmployerReference(){
  $('#user_reference').trigger("change");

  if ($("#reference_code_question").val() == "Yes" || $(":radio[name=reference_code_question]:checked").val() == 'Yes')
    $("#reference_code").show();
  else
    $("#reference_code").hide();
}

function shouldShowCustomReferenceField(value) {
  var pleaseSpecifyValues = [
    'Campus Student Group', 'Other', 'Advertisement',  'News Coverage', 'Social Media'
  ];

  return value && (value.match(/\(please specify\)$/) || pleaseSpecifyValues.includes(value));
}

global.referenceRegistrationOptionsEvents = function () {
  $("#user_reference_options input:radio, #user_reference").on('change', function () {
    if (this.value === 'Campus Ambassador'){
     $("#custom_reference_block").show();
     var reference = $("#ambassador_reference");
     if (reference.attr('state') == 'off') reference.prop('disabled', false);
     reference.show();
     $("#textual_reference").prop('disabled', true).val('').hide();
   } else if (shouldShowCustomReferenceField(this.value)){
     $("#custom_reference_block").show().find('input').val('');
     $("#textual_reference").prop('disabled', false).show();
     $("#ambassador_reference").prop('disabled', true).attr('state', 'off').val('').hide();
   } else {
     $("#ambassador_reference, #custom_reference_block, #textual_reference").hide();
     $("#custom_reference_block, #textual_reference").find('input, select').val("");
   }
  });

  $("#user_reference_options input:radio:checked, #user_reference").trigger('change');

  $('#reference_code_question_Yes').click(function(event){
   $('#reference_code').show();
  });

  $('#reference_code_question_No').click(function(event){
   $('#reference_code input').val("");
   $('#reference_code').hide();
  });

  showEmployerReference();
};

setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
  if(disableFileLoading('reference_registration_options')) return;

  referenceRegistrationOptionsEvents();
});