setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
  $(function(){
    jQuery.fn.hide_toggle_bar = function() {
      var toggle = jQuery(this);
      var callbackFunc = function() {
        toggle.removeClass("expanded");
        toggle.parent().removeClass("expanded");
      }
      var content = toggle.next('.content');
      content.hide();
      callbackFunc();
      resetSearchSidebarHeight();
    };

    jQuery.fn.show_toggle_bar = function() {
      jQuery(this).addClass("expanded");
      $(this).parent().addClass("expanded");
      jQuery(this).next('.content').slideDown();
      resetSearchSidebarHeight();
    };

    jQuery.fn.toggle_bar = function(event) {
      if (event && event.target) {
        var target = $(event.target);

        if (target.hasClass('edit-link') || target.parents('.edit-link').length) {
          return;
        }
      }

      if (jQuery(this).next('.content').css('display') == 'none') {
        jQuery(this).show_toggle_bar();
      } else {
        jQuery(this).hide_toggle_bar();
      }
    };

    function resetSearchSidebarHeight(){
      setTimeout(function(){
        $(".search-sticky-sidebar").sticky("update");
        fixSearchSidebar();
      }, 450);
    }

    jQuery('.title:not(.without-hiding)').on('click', function(event) {
      jQuery(this).toggle_bar(event);
    });
    jQuery('.title:not(.expanded, .without-hiding)').hide_toggle_bar();

    $('.read-more-profile').on('click', function(event) {
      $(this).parents('tr').next('tr').find('table').toggle();
      event.preventDefault();
    });
  });
})