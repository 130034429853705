$.fn.fixSidebar = function(bottomSpacing){
  var topSpacing = $(".main-section").length != 0 ? $(".main-section").offset().top : 0;

  var progressBar = $(".main-section .progress");
  if (progressBar.length != 0){
    var margin = parseInt($(".main-section .progress").css("margin-bottom")) + parseInt($(".main-section .progress").css("margin-top"))
    var progressHeight = progressBar.height() + margin;
    topSpacing += progressHeight + progressBar.height();
    bottomSpacing -= progressHeight;
  }

  $(this).sticky({ topSpacing: topSpacing , bottomSpacing: bottomSpacing });
}

function fixCandidateProfileSidebar(){
  if ($(".candidates.show").length != 0 && $(".sticky-sidebar").length != 0){
    var indent = getIndent(".sections .actions a.next_button", "margin-bottom");
    var bottomSpacing = getBottomSpacing(".candidates.show#main", indent);
    $(".sticky-sidebar").fixSidebar(bottomSpacing);
  }
}

function fixCommunicationSidebar(){
  if ($(".communication").length != 0 && $(".sticky-sidebar").length != 0){
    var bottomSpacing = getBottomSpacing(".communication.subpage");
    $(".sticky-sidebar").fixSidebar(bottomSpacing);
  }
}

function fixHelpSidebar(){
  var sidebar = $(".registration-fixed-sidebar");
  var mainContent = $(".left_column");
  if (sidebar.length != 0 && mainContent.length != 0 && sidebar.height() < mainContent.height()){
    var indent = getIndent("fieldset", "margin-bottom"),
        bottomSpacing = $(document).height() - $(".button_nav").last().offset().top + indent;
    $(".registration-fixed-sidebar").fixSidebar(bottomSpacing)
  }
}

global.fixSearchSidebar = function () {
  if ($(".search-sticky-sidebar").length != 0){
    var bottomElement = $(".left_column");
    if ($(".left_column").height() > $(".right_column").height()){
      if (bottomElement.length != 0) {
        var bottomSpacing = $(document).height() - (bottomElement.offset().top + bottomElement.height());
      } else {
        var bottomSpacing = 135;
      }
      $(".search-sticky-sidebar").fixSidebar(bottomSpacing);
    }
  }
}

function getIndent(selector, value){
  var indent = $(selector).css(value);
  indent = indent ? parseInt(indent) : 0
  return indent;
}

function getBottomSpacing(selector, indent){
  indent = (typeof indent === "undefined") ? 0 : indent;
  var bottomElement = $(selector);
  if (bottomElement.length != 0) {
    var bottomSpacing = $(document).height() - (bottomElement.offset().top + bottomElement.height()) - indent;
  } else {
    var bottomSpacing = 135;
  }
  return bottomSpacing;
}

setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
  fixCandidateProfileSidebar();
  fixCommunicationSidebar();
  fixHelpSidebar();
  fixSearchSidebar();
})
