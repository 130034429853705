global.gtmHandler = {};

gtmHandler.candidateRegSubmitEvent = (section, options, event) => {
  if (typeof dataLayer === 'undefined') return;

  dataLayer.push({
    event: 'candidate-reg-section-submit',
    section: section.data('section'),
    add_more: options.recordSaving,
    success: !event.error_source,
    error_source: event.error_source
  });
};

gtmHandler.candidateRegSectionScrollEvent = (delta) => {
  if (typeof dataLayer === 'undefined' || !delta || gtmHandler.hasScrollEvent) return;

  gtmHandler.hasScrollEvent = true;

  dataLayer.push({
    event: 'candidate-reg-scroll',
    direction: delta > 0 ? 'backward' : 'forward'
  });

  setTimeout(() => { gtmHandler.hasScrollEvent = false; }, 1000);
};

gtmHandler.candidateJobPostingView = () => {
  if (typeof dataLayer === 'undefined') return;

  dataLayer.push({ event: 'candidate-job-posting-view' });
};
