global.addressRelationsLoader = {
  initialize: function(options){
    $('#address_country_id').on('change', function(){
      addressRelationsLoader.loadStates();
    });

    $('#address_state_id').on('change', function(){
      addressRelationsLoader.loadCities();
    });

    $('#address_city_id').on('change', function(){
      if($(this).val() == 'Other'){
        $('#address_other_city').show().find("input").prop('disabled', false);
      } else {
        $('#address_other_city').hide().find("input").prop('disabled', true);
      }
    });

    var visibilitySelector = options && options.visible ? ':visible' : '',
        country_dropdown = $('#address_country_id' + visibilitySelector),
        state_dropdown = $('#address_state_id' + visibilitySelector),
        city_dropdown = $('#address_city_id' + visibilitySelector);

    if(country_dropdown.length > 0 && !!country_dropdown.val()) country_dropdown.trigger('change');
    if(state_dropdown.length > 0 && !!state_dropdown.val()) state_dropdown.trigger('change');
    if(city_dropdown.length > 0) city_dropdown.trigger('change');
  },

  loadStates: function(){
    var country_id = $('#address_country_id').val(),
        state_id = $('#state_id_value').val();

    if(country_id){
      $.ajax({
        type: "GET",
        url: "/countries/" + country_id + "/states/",
        data: {state_form_type: $('#state_form_type').val(), state_id: state_id},
        success: function(options) {
          var state_dropdown = $("#address_state_id");
          if(!!options && !!$.trim(options)){
            $("#region_section").hide().find('input').prop('disabled', true);
            $("#state_section").show().find('select').prop('disabled', false);
            if (state_dropdown.length > 0){
              addressRelationsLoader.loadStateOptions(state_dropdown, options);
              state_dropdown.val(state_id);
              addressRelationsLoader.loadCities();
            }
          } else {
            addressRelationsLoader.loadStateOptions(state_dropdown, options);
            $("#address_city_id option:not([class])").remove();
            addressRelationsLoader.loadWorldCities(country_id);
            $("#region_section").show().find('input').prop('disabled', false);
            $("#state_section").hide().find('select').prop('disabled', true);
          }
        }
      });
    } else {
      $("#region_section").hide();
      $("#state_section").hide();
    }
  },

  loadStateOptions: function(state_dropdown, options){
    state_dropdown.find('option:gt(0)').remove();
    state_dropdown.append(options);
  },

  loadCities: function(){
    var city_dropdown = $('#address_city_id'),
        state_dropdown = $('#address_state_id'),
        country_dropdown = $('#address_country_id'),
        country_id = country_dropdown.val(),
        state_id = state_dropdown.val(),
        city = $('#city_value').val();

    if(!state_id){
      city_dropdown.find("option:not([class])").remove();
      addressRelationsLoader.loadWorldCities(country_id);
    } else if (city_dropdown.length > 0 && country_id){
      $.get("/countries/" + country_id + "/states/" + state_id + "/cities",
        {city_name: $('#city_value').val(), city_form_type: $('#city_form_type').val()},
        function(options) {
          addressRelationsLoader.addCityOptions(options);
          city_dropdown.val(city);
      });
    }
  },

  loadWorldCities: function(country_id){
    if(country_id){
      $.ajax({
        type: "GET",
        url: "/countries/" + country_id + "/world_cities",
        data:  {city_name: $('#city_value').val(), city_form_type: $('#city_form_type').val()},
        success: function(options) {
          addressRelationsLoader.addCityOptions(options.html);
          var city = $('#city_value').val();
          $('#address_city_id').val(city);
        }
      });
    }
  },

  addCityOptions: function(options){
    var dropdown = $("#address_city_id");
    dropdown.find("option:not([class])").remove();
    dropdown.find('option.default').after(options);
  }
};

setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
  if(disableFileLoading('address_relations_loader')) return;

  addressRelationsLoader.initialize({ visible: true });
});
