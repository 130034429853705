global.load_vocational_degree = function (){
  var specialization = $("#vocational_specialization").val(),
      other_specialization = $("#college_vocational_specialization_id option:selected").text();

  if (specialization != '0'){
    var area = $("#college_area_of_study_id").val();
    if (area != '') load_vocational_specializations(area, specialization);
  }

  if (other_specialization == 'Other') {
    $("#vocational_specialization_block").hide();
    $("#other_vocational_specialization_block").show();
    $('#custom_specialization_block').hide().disable();
  } else if (other_specialization == 'Other (please specify)'){
    $("#custom_specialization_block").show().enable();
  }
};

//Level of education for candidates and recruiters
function load_vocational_specializations(area_id, specialization_id){
  if(area_id){
    $.ajax(
      {
      url: "/vocational_areas/" + area_id + "/vocational_specializations/",
      type: "GET",
      success: function (html) {
        var specialization = $("#college_vocational_specialization_id"),
            specialization_id = specialization_id || $("#college_vocational_specialization_val").val() ||
              $("#college_vocational_specialization_id").attr('data-value');

        specialization.html(html);

        if (specialization_id && $("#college_area_of_study_id :selected").text() != "Other" ){
          $("#college_vocational_specialization_id").val(specialization_id).change();
        }
      }
    }
    );
  }
};


global.candidateCollegeHandlerIsLoaded = false;

global.candidateCollegeHander = function () {
  if (candidateCollegeHandlerIsLoaded) return;

  candidateCollegeHandlerIsLoaded = true;

  $("#college_vocational_specialization_id").handle_custom_fields('#custom_specialization_block');

  $("#college_area_of_study_id").on("change", function () {
    var area_id = $(this).val(),
        other_id = $("#other_vocational_area_id").val();

    if (area_id == other_id) {
      $("#vocational_specialization_block").hide();
      $("#other_vocational_specialization_block").show();
      $("#college_vocational_specialization_id").val("");
      $('#custom_specialization_block').hide_and_disable();
    } else {
      $("#vocational_specialization_block").show();
      $("#other_vocational_specialization_block").hide();
      $("#college_other_academic_field").val("");
      if (area_id != "") {
        load_vocational_specializations(area_id);
      } else {
        $("#college_vocational_specialization_id").html("<option value=''></option>");
      }
    }
  });

  $("#college_area_of_study_id").change();
};

setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
  if(disableFileLoading('candidate_college')) return;

  candidateCollegeHandlerIsLoaded = false;
  candidateCollegeHander();
});
