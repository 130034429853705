global.accessibilityHandler = {};

accessibilityHandler.focusMdBlockField = function (block, options) {
  options = options || {};

  if (!$(block).hasClass('registration-section active') &&
      !$(block).parents('.registration-section.active').length ||
      !options.addRecordAction && $(block).find('*:focus').length) {
    return;
  }

  var field;

  if (options.prioritizeErrors) {
    field = $(block).find('.md-error:visible').siblings('[tabindex=0]:first');
  }

  if (!field || !field.length) {
    field = $(block).find('[tabindex=0]:first');
  }

  if (field.length) field.trigger('focus');
};

accessibilityHandler.changeSectionOnTabKey = function (e) {
  var prevElement = accessibilityHandler.prevFocusedElement,
      activeElement = $(document.activeElement);

  if (e.shiftKey && prevElement && prevElement.is('.registration-section, .active')) {
    navRegistrationHandler.changeSection('prev');
  } else if (!e.shiftKey && activeElement.is('.registration-section, .shadowed')) {
    navRegistrationHandler.changeSection('next');
  }

  accessibilityHandler.prevFocusedElement = activeElement;
};

accessibilityHandler.changeSectionOnEnterKey = function (target) {
  if (target.hasClass('add-btn') || target.hasClass('file-dialog') || target.hasClass('btn-action') ||
      target.hasClass('gpa_edit_field')) {

    return;
  }

  if (!target.hasClass('registration-section')) {
    target = target.parents('.registration-section:first');
  }

  if (target.length) {
    if (target.hasClass('active')) {
      navRegistrationHandler.changeSection('next');
    } else {
      target.trigger('click');
    }
  }
};

setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
  $('body').on('keydown', "[tabindex=0]", function(e) {
    if ((e.key === ' ' || e.key === 'Enter') && e.target && e.target.type !== 'text' && !$(e.target).is('textarea')) {
      if ($(e.target).attr('data-turbo-frame')) {
        e.target.click();
      } else {
        $(e.target).trigger('click');
      }

      e.stopPropagation();
    }
  });

  $('body').on('keyup', function(e) {
    if ($('#facebox:visible, .ac_results:visible').length) return;

    var target = $(e.target);
    if (target.parent(".select2-selection--multiple")) {
      return;
    }

    if (e.key === 'Enter') {
      accessibilityHandler.changeSectionOnEnterKey(target);
    } else if (e.key === 'Tab') {
      accessibilityHandler.changeSectionOnTabKey(e);
    } else if (!$('body').hasClass('using-keyboard')) {
      if (e.key === 'ArrowUp') {
        navRegistrationHandler.changeSection('prev');
      } else if (e.key === 'ArrowDown') {
        navRegistrationHandler.changeSection('next', [], { skipSaving: true });
      }
    }
  });

  $('body').on('mousedown', function(e) {
    $(this).removeClass('using-keyboard');
  });

  $('body').on('keydown', function(e) {
    if (e.key === 'Tab') {
      $(this).addClass('using-keyboard');
    }
  });
});
