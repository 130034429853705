global.commonRegistration = {
  saveNotEligibleEmail: function (){
    var email = $("#user_pending_registration_email").val();
    if (email)
      $.post("/unknown_university_emails", { "unknown_university_email[email]": email }, function() {
        if ($('#thanks_for_interest').length != 0)
          $.facebox($("#thanks_for_interest").html());
      }, "json");
  },

  registerNewOrganizationDomain: function(){
    var params = $("#new_user").serialize(),
        url = $("#new_organization_domain_url").val(),
        facebox = $("#facebox");

    $.post(url, params, function(){}, "script");
  },

  registerNewCandidateDomain: function (){
    $.post( "/candidate_domains", $('#new_user').serialize(), function() {}, 'script');
  },

  registerNewCandidateDomainAndRedirect: function(key, user) {
    $.post( "/candidate_domains", { user: user, key: key }, function() {}, 'script');
  },

  validateUser: function(options = {}) {
    var url = $("#validate_user_url").val(),
        form = $('#new_user'),
        params = "";

    this.fillPasswordConfirmation();
    form.find('#google_auth_data').remove();

    if (options.material) {
      form.find('.md-error').remove();

      var validatableFields = materialValidator.validatableFields(form);
      validatableFields.forEach(function (field) { materialValidator.showError(field); });

      if (form.find('.md-error:visible').not('.server-error').length) { return; }

      this.fillEmailConfirmation();
    }

    var inputs = form.find('input[type!=checkbox][type!=radio], select, :radio:checked, input:checked')
                     .not(':disabled, .g-recaptcha');

    inputs.each(function() {
      params = params + this.name + "=" + encodeURIComponent(this.value) + "&";
    });

    $.post(url, params, null, "script");
  },

  autoValidateGoogleUser: function() {
    var url = $("#validate_user_url").val(),
        form = $('#new_user'),
        googleAuthData = form.find('#google_auth_data').val(),
        validationAutoSubmit = form.data('validation-auto-submit');

    if (url && validationAutoSubmit && googleAuthData) {
      $.post(url, { google_auth_data: googleAuthData }, null, "script");
    }
  },

  fillPasswordConfirmation: function(){
    var password = $("#user_password"),
        passwordConfirmation = $("#user_password_confirmation");

    if (password && passwordConfirmation && passwordConfirmation.is(':hidden'))
      passwordConfirmation.val(password.val());
  },

  acceptTermsChoise: function() {
    var facebox = $('#facebox:visible'),
        value = facebox.find('[name="terms_and_conditions"]').val(),
        error = facebox.find('#terms_and_conditions_error');

    if (!value) {
      error.removeClass('hidden');
    } else {
      error.addClass('hidden');

      if (value !== "true"){
        $('.popup:visible .terms-main-content').addClass('hidden');
        $('.popup:visible .terms-confirm-declining-content').removeClass('hidden');
      } else {
        $.post("/users/update_terms_of_service", { "terms": value }, function(result){
          if (!result) {
            return false;
          }else {
            $("#user_terms_of_service").val(result);
            $(document).trigger('close.facebox');
          }
        }, "json");
      }
    }
  },

  confirmTermsDeclining: function(logoutPath) {
    window.location.href = 'logoutPath'
    alert('confirmTermsDeclining');
  },

  rejectTermsDeclining: function() {
    $('.popup:visible .terms-main-content').removeClass('hidden');
    $('.popup:visible .terms-confirm-declining-content').addClass('hidden');
  },

  termsParams: function(){
    var params = { facebox: $("#facebox:visible") };

    if (params.facebox.length != 0){
      params.terms = $("#facebox #user_terms_and_conditions").val();
      params.orgCode = $("#facebox #user_org_code").val();
    } else {
      params.terms = $("#user_terms_and_conditions").val();
      params.orgCode = $("#user_org_code").val();
    }

    return params;
  },

  validateOrgCode: function(callback){
    var params = this.termsParams(),
        queryParams = {"user[pending_registration_email]": $("#user_pending_registration_email").val(),
                       "user[org_code]" : params.orgCode,
                       "role" : $("#new_user [name='role']").val(),
                       "user[new_organization]" : $('#new_organization_question :radio:checked').val() };

    $.post("/registration_validation/org_code", queryParams, function(result){
      if (!result.valid){
        commonRegistration.showFaceboxError("org_code_error");
      }else{
          $("#user_terms_and_conditions, #terms_and_conditions").val(params.terms);
          $("#user_org_code").val(params.orgCode);
          if (params.facebox){
            $("#facebox #user_terms_and_conditions").val("");
            $("#facebox #user_org_code").val("");
          }

          $("#facebox:visible").find("#org_code_error").addClass('invisible');

          callback();
        }
      }, "json");
    },

    toogleUserTermsAndConditionErrors: function(terms){
      $("#facebox:visible")
        .find("#terms_and_conditions_error")
        .toggleClass('invisible hidden', terms === "true");
    },

    showFaceboxError: function(element_id){
      var facebox = $("#facebox:visible"), elem;
      if (facebox.length == 0)
        elem = $("#" + element_id);
      else
        elem = facebox.find("#" + element_id);

      if (typeof(elem) != 'undefined'){
        elem.removeClass("invisible hidden").show();
      } else{
        alert(elem.text());
      }
    },

    submitUserForm: function(params) {
      var form = $('#new_user');

      if (form.length) {
        form.find("#user_terms_and_conditions").val(params.terms);

        recaptchaHandler.refreshRecaptcha({
          action: 'sign_up',
          callback: function () {
            form[0].requestSubmit();
          }
        })
      }
    },

    createUser: function (options ) {
      var params = commonRegistration.termsParams();

      if (params.terms != "true") {
        this.toogleUserTermsAndConditionErrors(params.terms);
        return false;
      } else if (options.orgCode) {
        this.toogleUserTermsAndConditionErrors(params.terms);

        this.validateOrgCode(function() {
          commonRegistration.submitUserForm(params);
        });
      } else {
        this.submitUserForm(params);
      }
  },

  addAcademicSpecialty: function(link){
    $('#academic_specialty1').show();
    $('#academic_specialty1 select').prop('disabled', false);
    $('#second_major_link').hide();
    return false;
  },

  fillEmailConfirmation: function() {
    var emailConfirmation = $("#user_pending_registration_email_confirmation:hidden");

    if (emailConfirmation.length) {
      var email = $("#user_pending_registration_email");
      emailConfirmation.val(email.val());
    }
  },

  scrollToSignUpForm: function() {
    var formTopPosition = $('.signup-form').offset().top;
    $('html, body').animate({ scrollTop: formTopPosition }, 500, 'swing');
  }
};

global.videoHandler = {
  modalOpen: function(){
    var openVideoParam = urlHandler.getParam('video');
    if(openVideoParam == 'true'){
      $('.popup-video a').click();
    }
  },

  pause: function(){
    var iframe = $("#video-to-pop iframe");
    if (iframe.length != 0){
      $("iframe").each(function(){
        iframe[0].contentWindow.postMessage('{ "method": "pause" }', "https://player.vimeo.com");
      })
    }
  }

};

setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
  if(disableFileLoading('common_registration')) return;

  $("#create_candidate").on("click", function(event) {
    commonRegistration.validateUser();
    return false;
  });

  $('.popup-video a').on('click', function(e) {
    e.preventDefault();
    $('#video-to-pop').bPopup({
      onClose: function(){ videoHandler.pause(); }
    })
  });

  $('.user_academic_field_id').change(function(){
    var val = $(this).val();
    $(this).parents('.md-select').siblings('.academic_field_id').val(val);
  })

  $('#academic_specialty1 select').change(function(){
    var disabled = !$(this).val();
    $(this).parents('.md-select').siblings('input').prop('disabled', disabled);
  });


  commonRegistration.autoValidateGoogleUser();
  videoHandler.modalOpen();
});
