global.sessionHelper = {

  changeTimoutAfrerAction: function(){
    this.getTimeoutSettings(function(milliseconds, cookiesScope){
      var changeLogoutTime = true;

      $('body').on('mousemove', function() {
        if (changeLogoutTime) {
          sessionHelper.changeAuthCookies(milliseconds, cookiesScope);
          changeLogoutTime = false;
          setTimeout(function(){ changeLogoutTime = true; }, 5000);
        }
      });
    })
  },

  getTimeoutSettings: function(callback){
    var settings     = $('#session_settings');

    if (settings.length == 0) return;

    var minutes      = settings.attr('inactivity_time'),
        cookiesScope = settings.attr('cookies_scope');

    if ((/^\d+$/).test(minutes)){
      var time = parseInt(minutes) * 60000;
      if (time > 0) callback(time, cookiesScope);
    }
  },

  changeAuthCookies: function(milliseconds, cookiesScope){
    var time      = (new Date).getTime() + milliseconds,
        expiresAt = new Date(time);

    $.cookie('expires_at', time, {
      'expires': expiresAt, 'path': '/', 'domain': cookiesScope
    });
  },

  handleTimeout: function(){
    setInterval(function(){
      var expiresAt = $.cookie('expires_at'),
          expirationDate = expiresAt ? new Date(parseFloat(expiresAt)) : null;

      if (!expirationDate || expirationDate < new Date()) {
        window.location = "/logout?time_out=true";
      }
    }, 5000);
  }
}

$(function(){
  var expiresAt = $.cookie('expires_at');

  if (expiresAt) {
    sessionHelper.changeTimoutAfrerAction();
    sessionHelper.handleTimeout();
  }
})
