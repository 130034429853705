global.multiColumnSelectHandler = {};

multiColumnSelectHandler.initMultiColumnSection = function (section) {
  $(section).find('.multi-column-select .edit-btn, .multi-column-select .label').on('click', function(){
    $('.multi-column-select-list').hide();
    $(this).siblings('.multi-column-select-list').show().scrollToMakeVisible();
    $(this).siblings('.md-error').hide();
  });

  $(section).find('.multi-column-select-close').on('click', function(e) {
    $(this).parents('.multi-column-select-list').hide();
    e.preventDefault();
  });

  $(section).find('.select-column').on("enable", function(e) {
    if ($(this).hasClass("enabled")) {
      return;
    }

    e.stopImmediatePropagation();
    $(this).removeClass("disabled");
    $(this).addClass("enabled");
  });

  $(section).find('.select-column li').on('click', function(){
    if ($(this).parent().hasClass("disabled")) {
      return false;
    }

    var column = $(this).closest('ul'),
        select = $(this).closest('.multi-column-select-list'),
        box = select.siblings('.multi-column-select-box'),
        required = select.siblings('.md-error').length > 0,
        input = box.find("input[name*='" + column.attr('input_key') + "']"),
        parent = box.parent();

    $(this).addClass('selected').siblings('.selected').removeClass('selected');
    input.val($(this).attr('val'));

    if (parent.isMultiSelectAnswered()){
      parent.next('.md-error').remove();
      parent.find('.md-error').hide();
      setTimeout(function() { select.hide(); }, 500);
    }

    parent.updateMultiColumnSelectText({ required: required });
    parent.find('.select-column.disabled').trigger("enable");

    if (autoScrollHandler.isReadyForAutoscroll(parent)) {
      navRegistrationHandler.changeSection('next');
    }
  });


  $(section).find('.multi-column-select').updateMultiColumnSelectText({ required: false });
};

setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
  $(document).on('keyup', function(e){
    if(e.key === 'Escape') {
      $(".multi-column-select-list:visible").hide();
    }
  });

  $('body').on('keyup', '.multi-select-column .select-column li, .multi-column-select-close', function(e) {
    if (e.key === ' ' || e.key === 'Enter') {
      $(e.target).trigger('click');
    }
  });
});
