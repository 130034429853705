global.assistantFormHandler = {};

assistantFormHandler.handlePortalChange = (element) => {
  var portalId = element.value;

  if (portalId) {
    var url = '/admin/portal/' + portalId + '/portal_industries';
    $.get(url, null, function(){}, 'script')
  }
};

assistantFormHandler.handleZipCodeChange = (element) => {
  var id = element.value;
  $.get('/career_centers',  { region_id: id }, null, 'script');
};
