class Patcher {
  constructor(browser) {
    this.setIndexOf();
    this.setToCamelCase();
    if (browser.msie) {
      this.setFiler();
      this.setTrim();
    }
  }


  setIndexOf() {
    if (!Array.prototype.indexOf) {
      return Array.prototype.indexOf = function(obj, start) {
        let i = start || 0;
        let j = this.length;
        while (i < j) {
          if (this[i] === obj) {
            return i;
          }
          i++;
        }
        return -1;
      };
    }
  }


  setToCamelCase() {
    if (!String.prototype.toCamelCase) {
      String.prototype.toCamelCase = function() {
        var arr = this .split(/[_-]/),
            newStr = '';

        for (var i = 1; i < arr.length; i++) {
            newStr += arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }

        return arr[0] + newStr;
      };
    }
  }

  setFiler() {
    //filter
    if (!Array.prototype.filter) {
      Array.prototype.filter = function(fun) {
        'use strict';
        if (this === null) {
          throw new TypeError;
        }
        let t = Object(this);
        let len = t.length >>> 0;
        if (typeof fun !== 'function') {
          throw new TypeError;
        }
        let res = [];
        let thisp = arguments[1];
        let i = 0;
        while (i < len) {
          if (i in t) {
            let val = t[i];
            // in case fun mutates this
            if (fun.call(thisp, val, i, t)) {
              res.push(val);
            }
          }
          i++;
        }
        return res;
      };
    }
  }

  setTrim() {
    if (typeof String.prototype.trim !== 'function') {

      String.prototype.trim = function() {
        return this.replace(/^\s+|\s+$/g, '');
      };
    }

  }
}

document.addEventListener('DOMContentLoaded', function(event) {
  new Patcher($.browser);
});
