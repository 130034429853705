global.materialValidator = {
  validateForm: function (form, event) {
    this.validatableFields(form).forEach(function (field) {
      materialValidator.showError(field);
    });

    var errors = $(form).find('.md-error:visible').not('.server-error');
    if (errors.length && event) event.preventDefault();

    return !errors.length;
  },

  validatableFields: function (section) {
    var source = $(section).attr('data-md-wrapper') === 'false' ?
        $(section) :
        $(section).find('.md-textfield, .md-choise-group, .md-select').not(':hidden'),

        fields = source.find('[pattern], [required], [min], [max]').toArray(),
        hiddenFields = $(section).find("input[type='hidden'][required]").toArray();

    return fields.concat(hiddenFields);
  },

  errorType: function(obj){
    if (!obj || !obj.validity) return;

    if (obj.required && (obj.validity.valueMissing || obj.type === 'hidden' && !obj.value)) {
      return 'required';
    } else if (obj.validity.patternMismatch && obj.pattern) {
      return 'pattern';

    } else if (obj.validity.rangeUnderflow || obj.validity.rangeOverflow){
      if (obj.min && obj.max){
        return 'range';
      }
    }
  },

  errorMessage: function(obj, type){
    if (type == 'required'){
      return 'Cannot be blank';
    } else if (type == 'pattern') {
      return obj.getAttribute('pattern_error') || 'Invalid format';
    } else if (type == 'range') {
      return 'Must be between ' + obj.min + ' and ' + obj.max;
    };

    return obj.validationMessage;
  },

  findErrorSibling: function(fieldBlock){
    var nextElement = fieldBlock.next();
    return nextElement.is('label') ? nextElement : fieldBlock;
  },

  showError: function(field, error){
    field = $(field);

    var fieldBlock, errorType = materialValidator.errorType(field[0]);

    if (field.is(':radio') || field.is(':checkbox')) {
      fieldBlock = field.parents('.md-choise-group');
    } else if (field.hasClass('multi-column-select-val')) {
      fieldBlock = field.parents('.multi-column-select');
    } else {
      fieldBlock = field;
    }

    error = error || materialValidator.errorMessage(field[0], errorType);

    if (error) {
      fieldBlock.addClass('is-invalid');
      materialValidator.showFieldError(fieldBlock, error);
    } else {
      fieldBlock.removeClass('is-invalid');
      this.findErrorSibling(fieldBlock).next('.md-error').remove();
    }
  },

  showFieldError: function(fieldBlock, error) {
    if (!fieldBlock.length || !error) return;
    fieldBlock.addClass('is-invalid');

    var errorSibling = this.findErrorSibling(fieldBlock),
        errorBlock = $(errorSibling).next();

    error = error.charAt(0).toUpperCase() + error.slice(1);

    if (errorBlock.hasClass('md-error')) {
      errorBlock.text(error);
    } else {
      errorSibling.after('<div class="md-error">' + error + '</div>');
    }
  },

  removeErrors: function() {
    $(".md-error:not('.static-error')").remove();
    $('.is-invalid').removeClass('is-invalid');
  }
};

setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
  $('body').on("paste keyup", ".md-textfield input:visible, .md-textfield textarea:visible", function(){
    materialValidator.showError(this);
  });

  $('body').on("change", ".md-choise input[type='radio'], .md-choise [type='checkbox']", function(e) {
    if (e.originalEvent !== undefined) {
      materialValidator.showError(this);
    }
  });

  $('body').on("change", '.md-select select:visible', function(e){
    if (e.originalEvent !== undefined) {
      materialValidator.showError(this);
    }
  });

  $('.server-validation .md-error:visible').each(function() {
    $(this).prev().find('input, select').not(':hidden').addClass('is-invalid');
  });
});
