setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
  $(document).on('click',".search-result .table-flex-row", function(){
    $(this).toggleClass("active");
  });

  $(document).on('click', ".search-result .table-flex-row .icon-more", function(){
    $(this).prev(".more-action").toggleClass("active");
  });

  $(document).on('click', ".table-options .icon-more", function(){
    $(this).prev(".table-options-block").toggleClass("active");
  });
});