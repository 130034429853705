setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
  $(document).on('focus', '.md-multiselect-dropdown .search-field input', function(){
    $(this).parents('.md-multiselect-dropdown').find('.content').show();
  });

  $(window).on('click', function(e) {
    $('.md-multiselect-dropdown').each(function(_index, dropdown) {
      if (!dropdown.contains(e.target)) {
        $(dropdown).find('.content').hide();
      }
    });
  });

  $(document).on('change', '.md-multiselect-dropdown :checkbox', function(){
    $(this).parents('.md-multiselect-dropdown').find('.search-field input').val('').trigger('paste');
  });

  $(document).on('input propertychange paste', '.md-multiselect-dropdown .search-field input', function(){
    var fields = $(this).parents('.md-multiselect-dropdown').find('.md-choise'),
        searchValue = this.value;

    fields.each(function(_index, label){
      if (!searchValue || label.textContent.includes(searchValue)) {
        $(label).show();
      } else {
        $(label).hide();
      }
    });
  });
});