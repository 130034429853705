global.candidateRegistrationHelper = {};

candidateRegistrationHelper.completeAssessmentWarning = function (){
  var warning = $(".assessment-warning-facebox");
  if (warning.length != 0) {
    $.facebox($('.assessment-warning-facebox').html());
    return false;
  }
};

candidateRegistrationHelper.createProposedField = function () {
  var facebox = $("#facebox");
  var name = facebox.find("#proposed_field");
  var candidate_id = facebox.find("#candidate_id").val();
  var table = facebox.find("#field_table_name").val();

  $.ajax(
    {
      type: "POST",
      data: {"name": name.val(), "candidate": candidate_id, "field_table_name": table },
      url: "/proposed_fields",
      dataType: "json",
      success: function (result) {
        var errors = result.errors;
        if(errors && errors.name && errors.name.length){
          name.addClass("error_input_border");
          error = errors.name[0];
          if(error == "has already been taken"){
            error = "already exists.";
          }
          $("#facebox").find(".proposed_name_error").text("Field " + error);
        }else{
          $("#job_requirement_field_of_interest_id, #academic_specialty_academic_field_id").val('').change();
          $.facebox.close();
        }
      }
    }
  );
};

setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
  $('body').on("blur", ".mobile-number input, .phone-number input", function(event) {
    var groups = this.value.match(/^\s*\(?(\d{3})\)?(\d{3})(.*)/);
    if (groups) {
      $(this).val(groups[1] + "-"+ groups[2] + "-" + groups[3]);
    }
  });

  $(".candidates.show").on("click", '#change_graduation_date', function(event) {
    var facebox = $("#facebox");
    var semester = facebox.find("#user_user_candidate_status_expected_graduation_semester");
    var year = facebox.find("#user_user_candidate_status_expected_graduation_year");
    $.ajax(
      {
        type: 'PUT',
        data: {"semester": semester.val(), "year": year.val()},
        url: "/candidate/graduation_date",
        dataType: "json",
        success: function (result) {
          var errors = result.errors;
          if(errors){
            if ($.inArray("semester", errors) >= 0){
              semester.addClass("error_input_border");
              $("#facebox").find(".expected_graduation_semester_error").text("Semester cannot be blank.");
            }else{
              semester.removeClass("error_input_border");
              $("#facebox").find(".expected_graduation_semester_error").text("");
            }

            if ($.inArray("year", errors) >= 0){
              year.addClass("error_input_border");
              $("#facebox").find(".expected_graduation_year_error").text("Year cannot be blank.");
            }else{
              year.removeClass("error_input_border");
              $("#facebox").find(".expected_graduation_year_error").text("");
            }
          }else{
            location.reload(true);
          }
        }
      }
    );
  });

  var interview_time = $("#interview_scheduled_time_4i");
  if (interview_time.length != 0){
    interview_time.siblings("input:hidden").each(function(){
      $(this).remove();
    })
  }

  // odd-even classes for errors
  var errors = $(".select_errors");
  if (errors.length != 0){
    $(".even").first().siblings(".error_tr").each(function(){
      var prev = $(this).prev();
      if (prev.hasClass('odd')) {
        $(this).removeClass("even");
        $(this).addClass("odd");
      }
      else{
        $(this).removeClass("odd");
        $(this).addClass("even");
      }
    })
  }

  $(".candidate_privacy_checkbox").change(function(){
    var title = $(this).prop("title");
    var oppositeTitleField = $(this).siblings(".privacy_opposite_value");
    if (oppositeTitleField.length != 0){
      $(this).prop("title", oppositeTitleField.val());
      oppositeTitleField.val(title);
    }
  });
});
