(function ($) {
  $.fn.serialize = function (options) {
    return $.param(this.serializeArray(options));
  };

  $.fn.serializeArray = function (options) {
    var o = $.extend({
      checkboxesAsBools: false
    }, options || {});

    var rselectTextarea = /select|textarea/i;
    var rinput = /text|number|hidden|password|search/i;

    return this.map(function () {
      return this.elements ? $.makeArray(this.elements) : this;
    })
    .filter(function () {
      return this.name && !this.disabled &&
        (this.checked
         || (o.checkboxesAsBools && this.type === 'checkbox')
         || rselectTextarea.test(this.nodeName)
         || rinput.test(this.type));
    })
    .map(function (i, elem) {
      var val = $(this).val();
      var value;
      if (o.checkboxesAsBools && this.type === 'checkbox'){
        if ($(this).prop("name").split("[]").length < 2){
          value = this.checked ? 'true' : 'false';
        } else {
          value = this.checked ? val : "";
        }
      } else {
        value = val;
      }
      return val == null ?
        null :
        $.isArray(val) ?
        $.map(val, function (val, i) {
        return { name: elem.name, value: val };
      }) :
        {
        name: elem.name,
        value: value
      };
    }).get();
  };

})(jQuery);
