global.messageHandler = {
  permissionMessage: function() {
    $.facebox("You have reached the daily limit of contacts to new candidates.");
  },

  showPopup: function(data, callback){
    var permission = $.cookie('candidate_message_permission');

    if (permission == "false"){
      messageHandler.permissionMessage();
    } else {
      $.ajax({
        type:     'GET',
        url:      '/messages/new',
        data:     data,
        async:    false,
        success: function(response){
          if   (response.error_message) $.facebox(response.error_message)
          else callback(response);
        },
        dataType: 'json'
      })
    }
  },

  singleMessage: function(id, callback) {
    id = id || $('#message_recipient_id').val();

    this.showPopup({ user_id: id }, callback);
  },

  singleRequest: function(type) {
    this.singleMessage(null, function(response) {
      var sectionToHide = type == 'offer' ? 'interview' : 'offer',
          facebox = $('#recruiter_message_box').show();

      messageHandler.setTemplates(facebox, response);
      $.facebox(facebox);

      showMessageByType(type + '_fields', type, sectionToHide + '_fields');
      facebox.find('#send_message').show();
    });
  },

  multipleMessages: function() {
    var ids = $("#selected_candidate_ids").val().split(',').filter(Boolean);

    $("input[name='candidate_ids']:checked").each(function(){
      if (this.value) ids.push(this.value);
    });

    if (ids.length > 0) {
      $("#send_message #message_recipient_id").val(ids[0]);
      $("#send_message #message_recipients").val(ids);

      var facebox = $('#send_message').clone(),
          data = { user_ids: ids.join(',') };

      this.showPopup(data, function(response){
        var recipientNames = facebox.find("#recipient_names"),
            recipientLabel = recipientNames.siblings(".label");

        recipientNames.text(response.user_names.join(', '));
        recipientLabel.text(response.user_names.length > 1 ? 'Bcc:' : 'To:');

        messageHandler.setTemplates(facebox, response);
        $.facebox(facebox.html());
      });
    } else {
      $("#send_message").hide();
      $.facebox("Please select candidates to send message.");
    }
  },

  replyToMessage: function(messageId, checkPermission) {
    if(messageId){
      $.get(
        "/messages/"+ messageId + "/reply",
        null,
        function (result) {
          $('#send_message').fixFaceboxAccessibility('template');
          var popupContent = $('#send_message').clone();

          popupContent.find("#message_recipient_id").val(result.recipient_id);
          popupContent.find("#message_base_id").val(result.base);
          popupContent.find("#recipient_names").text(result.recipient_name);
          popupContent.find("#message_subject_facebox").val(result.subject);
          popupContent.find("#message_body_facebox").val(result.body);

          messageHandler.setTemplates(popupContent, result);

          if (checkPermission == 'true') {
            messageHandler.singleMessage(result.recipient_id, function(){
              $.facebox(popupContent.show());
            });
          } else {
            $.facebox(popupContent.show());
          }
        },
        "json"
      );
    }
  },

  replyPopupBySelector: function (selector) {
    var html = $(selector).clone().show().get(0).outerHTML;
    $.facebox(html);
  },

  replyInterviewPopup: function (selector, status) {
    $(".edit_interview, .edit_offer").find("#interview_status, #offer_status").val(status);
    this.replyPopupBySelector(selector);
  },

  candidateDetailsRequestPopup: function (target) {
    target = target || this;

    var action            = $(target).attr("action"),
        organization_name = $(target).attr("organization_name"),
        candidate_name    = $(target).attr("candidate_name"),
        id                = $(target).attr('href');

    $.facebox($(id).html());

    if (organization_name) {
      $("#facebox").find("#request_organization_name").text(organization_name);
    }
    $("#facebox").find(".candidate_name").text(candidate_name);
    $("#facebox").find(".respond_link").each(function() {
      var status = $(this).attr("status");
      $(this).prop("href", action + "?status="+ status);
    });
  },

  setTemplates: function(facebox, response) {
    var templates = response.templates;

    if (templates) {
      messageHandler.bodies = templates.bodies;

      if (templates.select_options) {
        facebox.find('#message_template').empty().append(templates.select_options);
      }
    }

    $.facebox(facebox.html());
  },

  handleTemplateChange: function(select) {
    if (messageHandler.bodies) {
      var body = messageHandler.bodies[select.value],
          subject = body ? $(select).find('option:selected').text() : '',
          faceboxForm = $('.new_message_form:visible');

      faceboxForm.find('#message_body').val(body);
      faceboxForm.find('#message_subject').val(subject);

      $('.new_message_form:visible').find('#message_body').val(body);
    }
  },

  triggerThreadMessageReply: function() {
    $('.thread-reply-via-message').trigger('click')
  }
};

setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
  $("#send_messages").on("click", function() {
    messageHandler.multipleMessages();
    return false;
  });

  $("#send_message_to_candidate").on("click", function() {
    messageHandler.singleMessage(null, function(response) {
      var facebox = $('#send_message');
      messageHandler.setTemplates(facebox, response);
      $.facebox(facebox.html());
    });

    return false;
  });

  $("#send_interview_to_candidate").on("click", function(event) {
    messageHandler.singleRequest('interview');
    return false;
  });

  $("#send_offer_to_candidate").on("click", function(event) {
    messageHandler.singleRequest('offer');
    return false;
  });

  $(".send_message_to_recruiter").on("click", function(event) {
    var form = $(".new_message_form");
    form.find("#message_recipient_id").val($(this).attr("recruiter_id"));
    form.find("#recipient_names").text($(this).attr("recruiter_name"));
    return false;
  });

  $(".reply_message").on("click", function() {
    var messageId       = $(this).attr("message_id"),
        checkPermission = $(this).attr("check_permission");

    messageHandler.replyToMessage(messageId, checkPermission);

    return false;
  });
});
