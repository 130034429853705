global.mdSession = {};

mdSession.loginUser = function () {
  var form = $(".login-form:visible"),
      url = form.prop('action'),
      params = "",
      availableFields = [
        "input[type='text']", "[type='password']",
        "input:checked", "[name='g-recaptcha-response-data[login]']",
        "[name='g-recaptcha-v2-response']"
      ];

  form.find('.error').html('');
  materialValidator.validatableFields(form).forEach(function (field) {
    materialValidator.showError(field);
  });

  if (form.find('.md-error:visible').not('.server-error').length) { return; }

  form.find(availableFields.join(', ')).each(function() {
    params = params + this.name + "=" + encodeURIComponent(this.value) + "&";
  });

  mdFormHelper.showSpinner();
  $.post(url, params, function() {}, "script");
};
