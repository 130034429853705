(function( $ ){

  $.fn.popupLink = function( options ) {

    var settings = $.extend({ sizes: 'height=300,width=400' }, options);

    $(this).on('click', function() {
      var popupSettings = $(this).attr("data-popup") || settings.sizes;
      window.open($(this).prop('href'), "", popupSettings);
      return false;
    });

  };
})( jQuery );


setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
  $('a[data-popup]').popupLink();
});
