global.formHandler = {
  showDatepicker: function(){
    var imgId, fieldId,
        selectors = [["start_time_datepicker", "start_time_button"],
                     ["end_time_datepicker", "end_time_button"]];

    $('.datepicker_img').each(function(){
      imgId = $(this).prop('id');
      fieldId = $(this).data('field-id') || $(this).siblings('input').first().prop('id');
      if (imgId && fieldId) selectors.push([fieldId, imgId]);
    });

    $.each(selectors, function(index, value){
      var element = $("#" + value[0]);
      if (element.length == 1){
        Calendar.setup({
          inputField : value[0],
          trigger    : value[1],
          onSelect   : function() {
            this.hide();
            element.trigger('focus');
          },
          onFocus    : function() {
            var triggerEl = document.getElementById(this.args.trigger),
                rect = triggerEl.getBoundingClientRect();

            $(this.els.topCont).css({ top: `${rect.top + 20}px`, left: `${rect.left}px` });
          },
          showTime   : false,
          dateFormat : "%m/%d/%Y",
          animation: false,
          fdow: 0
        });
      }
    })
  }
};

(function($){
  $.fn.disable = function() {
    $(this).find('input, select').prop('disabled', true);
    return $(this)
  };

  $.fn.enable = function() {
    $(this).find('input, select').prop('disabled', false);
    return $(this)
  };

  $.fn.clear_not_hidden = function() {
    $(this).find('input, select').not('[type=hidden]').val('')
    return $(this)
  };

  $.fn.hide_and_disable = function() {
    $(this).hide().disable().clear_not_hidden();
    return $(this)
  };

  $.fn.show_and_enable = function() {
    $(this).show().enable().clear_not_hidden();
    return $(this)
  };

  $.fn.disableHidden = function() {
    $(this).is(':visible') ? $(this).enable() : $(this).disable();
    return $(this)
  };

  $.fn.removeOptions = function() {
    $(this).find('option').not(':first').remove();
    return $(this);
  };

  $.fn.resetOptions = function(options) {
    var firstOption = $(this).removeOptions().html(),
        value = $(this).val();

    $(this).html(firstOption + options);
    if (value) $(this).val(value);

    return $(this);
  };

  $.fn.handle_custom_fields = function(section_selector) {
    var section = $(section_selector);

    section.disableHidden();
    $(this).change(function () {
      var selected_option = $(this).find("option:selected"),
          selected_text = selected_option.text();

      if (!!selected_option.val() && selected_text && !!selected_text.match(/^Other/)){
        section.show().enable();
      } else {
        section.hide().hide_and_disable();
      }
      return $(this)
    });
  };

  $.fn.clickOff = function(callback) {
    var clicked = false, parent = this;

    parent.click(function() { clicked = true; });

    $(document).click(function(event) {
      if (!clicked) callback(parent, event);
      clicked = false;
    });
  };

  $.fn.removeErrors = function() {
    var errors = $(this).find('.fieldError');
    errors.prev('br').remove();
    errors.remove();
  };

  $.fn.hide_message_box = function(callback) {
    var toggle = jQuery(this);
    var callbackFunc = function() {
      toggle.removeClass("expd");
      if(callback) {
        callback();
      }
    }
    var content = jQuery('#' + jQuery(this).attr('rel'));
    content.slideUp(callbackFunc);
  };

  $.fn.show_message_box = function() {
    var toggle = this;
    var callback = function() {
      var rel = jQuery(toggle).attr('rel');
      if (rel){
        jQuery(toggle).addClass("expd");
        jQuery('#' + rel).slideDown();
      }
    }

    var expanded = jQuery(".value_box.expd");
    if(expanded.length > 0) {
      expanded.hide_message_box(callback);
    } else {
      callback();
    }
  };

  $.fn.toggle_selected_cell = function() {
    $(this).parents('td').toggleClass('selected');
  };

  $.fn.regex = function(pattern, targetFn, targetName){
    targetFn = targetFn || $.fn.text;
      return this.filter(function() {
          return pattern.test(targetFn.apply($(this), targetName));
      });
  };

  $.fn.buidFormData = function(options) {
    var fields = this.find('input, select, textarea').not(':disabled'),
        fileFields = fields.filter(function(_i, f) { return f.type === 'file'; }),
        fieldParams = fields.serializeArray({ checkboxesAsBools: true }),
        formData = new FormData();

    $.each(fieldParams, function(_index, param) {
      formData.append(param.name, param.value);
    });

    if (options && options.extraParams) {
      $.each(options.extraParams, function(_index, param) {
        formData.append(param.name, param.value);
      });
    }

    $.each(fileFields, function(_index, field) {
      if (field.files && field.files.length) {
        $.each(field.files, function(_index, file) {
          formData.append(field.name + '[]', file);
        });
      }
    });

    return formData;
  }

})(jQuery);
