global.tooltipHandler = {};

tooltipHandler.setTippyTooltips = function () {
  tippy('[data-tippy-content]', { allowHTML: true, touch: true });
};

tooltipHandler.hideTippy = function (selector) {
  var element = document.querySelector(selector);

  if (element && element._tippy) {
    element._tippy.hide();
  }
};

tooltipHandler.setEventListeners = function() {
  tooltipHandler.setTippyTooltips();

  tippy('[data-tippy-dropdown]', {
    content: function(target) {
      return $(target.dataset.tippyDropdown).html();
    },
    allowHTML: true,
    interactive: true,
    trigger: 'click',
    theme: 'dropdown',
    placement: 'bottom-start'
  });
};

tooltipHandler.setSwitcherDropdowns = function () {
  tippy('[data-tippy-switcher-dropdown]', {
    content: function(target) {
      return $(target.dataset.tippySwitcherDropdown).html();
    },
    trigger: 'click',
    arrow: false,
    allowHTML: true,
    interactive: true,
    placement: 'bottom',
    theme: 'switcher-dropdown',
    offset: [0, 0],
    popperOptions: {
      modifiers: [
        { name: 'flip', enabled: false }
      ]
    },
    onShow(target) {
      $(target.reference).find('i').text("expand_less");
    },
    onHidden(target) {
      $(target.reference).find('i').text("expand_more");
    }
  });
};

setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
  tooltipHandler.setSwitcherDropdowns();
  tooltipHandler.setEventListeners();
});
