setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
  $('#profile_navigation a').click(function() {
    $('#profile .more_details .title').hide_toggle_bar();
    $('#profile ' + $(this).attr('href') + " .title").show_toggle_bar();
  });

  $('#attachments .button.delete').hover(function() {
    $(this).parents('li').addClass('delete_alert');
  }, function() {
    $(this).parents('li').removeClass('delete_alert');
  });

  $('.sms_permissions').on('change', function(){
    var value = !$(this).is(':checked');

    $.ajax({ type: "PUT",
      data: {"user_permission": {"sms": value}},
      url: "/user_permission/",
      dataType: "json"
    })
  })
});
