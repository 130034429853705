global.fileDropHandler = {};

fileDropHandler.showFile = function(element, files) {
  if (!files || !files.length) return;

  var mdFileDrop = element.hasClass('md-file-drop') ? element : element.siblings('.md-file-drop');
      targetField = $(mdFileDrop.attr('data-target-field'));

  mdFileDrop.find('.md-dropped-file').removeClass('hidden');
  mdFileDrop.find('.md-file-name').text(files[0].name);
  targetField.next('.md-error').remove();
};

fileDropHandler.initMdFileDrop = function(selector) {
  var dropSection = $(selector);

  dropSection.on('drag dragstart dragend dragover dragenter dragleave drop', function(e) {
    e.preventDefault();
    e.stopPropagation();
  });

  dropSection.on('dragover dragenter', function() {
    $(this).addClass('is-dragover');
  });

  dropSection.on('dragleave dragend drop', function() {
    $(this).removeClass('is-dragover');
  });

  dropSection.on('drop', function(e) {
    var files = e.originalEvent.dataTransfer.files,
        targetFieldSelector = $(this).attr('data-target-field'),
        fileField = $(targetFieldSelector)[0];

    if (fileField) {
      fileField.files = files;
      fileDropHandler.showFile($(this), fileField.files);
    }
  });
};

fileDropHandler.showFileDialog = function(selector, event) {
  var targetFieldSelector = $(selector).attr('data-target-field'),
      targetField = $(targetFieldSelector),
      target = $(event.target);

  if (target.hasClass('btn-remove') || target.parents('.btn-remove').length) {
    targetField.resetMdFileField();
    event.preventDefault();
  } else if (targetField !== target) {
    targetField.trigger('click');
  }
};

fileDropHandler.initFileDialogElements = function(selector) {
  $(selector).on('click', function(e) {
    fileDropHandler.showFileDialog(selector, e);
  });

  $(selector).on('keyup', function(e) {
    if (e.key === 'Enter') {
      fileDropHandler.showFileDialog(selector, e);
    }
  });
};
