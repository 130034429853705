global.templateHandler = {}

templateHandler.updateTextFromDataAttribute = function(element, dataAttribute, targetProp, variable, value) {
  var message = element.data(dataAttribute);

  if (message) {
    message = message.replace(variable, value);

    if (targetProp === 'text') {
      element.text(message);
    } else {
      element.prop(targetProp, message);
    }
  }
}
