global.graduationDateHandler = {};

graduationDateHandler.changeYear = function (years, inputId, direction) {
  var yearsSize = years.length - 1,
      year = $.trim($(".year-title").text()),
      parsedYear = year === 'All' ? year : parseInt(year),
      selectedIndex = years.indexOf(parsedYear);

  if (direction === 'back' && selectedIndex <= yearsSize){
    selectedIndex = selectedIndex + 1;
  } else if (selectedIndex <= yearsSize && selectedIndex != 0){
    selectedIndex = selectedIndex - 1;
  }

  $(".year-title").html(years[selectedIndex]);
  $(inputId).val(years[selectedIndex]);
};

graduationDateHandler.selectSemester = function (semesterId, inputId) {
  $(".grad-date-block .semesters").find('.semester-inner').removeClass("active");
  $('.grad-title' + '#semester-' + semesterId).parents('.semester-inner').addClass('active');

  $(inputId).val(semesterId);
  materialValidator.showError(inputId);
};
