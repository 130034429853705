global.newFlash = function (){
  $(".flash").remove();

  $("body").first().prepend(
    "<div class='flash'>\
      <span id='dismiss_notice_button'></span>\
      <a href='#' class='link_button md-btn md-primary-btn md-blue-btn'\
         onclick='dismiss_notice(this)'>Dismiss Notice</a>\
    </div>"
  );

  $(".flash").hide();
};

global.showFlash = function (type, html){
  $(".flash").show().addClass(type).prepend(html);
};

global.showNewFlash = function (type, html){
  if (html) {
    newFlash();
    showFlash(type, html);
  }
};
