global.mdFormHelper = { ajaxRequests: 0 };

mdFormHelper.showSpinner = function () {
  ++mdFormHelper.ajaxRequests;
  var scrollHeight = document.documentElement.scrollHeight;
  $('.spinner').css('height', scrollHeight + 'px').removeClass('hidden');
};

mdFormHelper.hideSpinner = function () {
  if (mdFormHelper.ajaxRequests > 0) {
    --mdFormHelper.ajaxRequests;
  }

  if (mdFormHelper.ajaxRequests <= 0) {
    $('.spinner').addClass('hidden');
  }
};

mdFormHelper.selectors = {
  text: '.md-textfield input, .md-textfield textarea',
  choise: '.md-choise.md-bordered input, .md-choise-border input, .md-fab-radio input',
  select: '.md-select select'
};

mdFormHelper.handleMdFieldChange = function (field, value) {
  if (value) {
    $(field).addClass('filled');
  } else {
    $(field).removeClass('filled');
  }
};

mdFormHelper.handleMdChoiseFieldChange = function (field) {
  var choiseGroup = $(field).parents('.md-choise-group'),
      parentSelectors = '.md-choise.md-bordered, .md-choise-border, .md-fab-radio';

  choiseGroup.find(parentSelectors).find('input').each(function(){
    var isChecked = $(this).is(':checked');
    $(this).parents(parentSelectors).toggleClass('filled', isChecked);
  });

  if (choiseGroup.hasClass('md-checkbox-group-required')) {
    var groupCheckboxes = choiseGroup.find("[type='checkbox']");
    groupCheckboxes.prop('required', !groupCheckboxes.is(':checked'));
  }
};

mdFormHelper.querySelectorAllRegex = function (regex, attributeToSearch) {
  var output = [];

  document.querySelectorAll('[' + attributeToSearch + ']').forEach(function(elemnt) {
    if (regex.test(element.getAttribute(attributeToSearch))) {
      output.push(element);
    }
  });

  return output;
};

mdFormHelper.changeSearchOperator = function (event) {
  var val = event.target.innerText,
      parent = $(event.target).parents('.md-toggle');

  parent.find("[type='hidden']").val(val);
  parent.find('.md-toggle-option span').removeClass('active');
  $(event.target).addClass('active');
};

mdFormHelper.toggleMdPassword = function (el) {
  var input = $(el).prevAll('.password-input'),
  newType = input.attr('type') === 'text' ? 'password' : 'text';

  input.attr('type', newType);
  $(el).toggleClass('hide-pwd', newType == 'text');
};

(function ($) {
  var originalVal = $.fn.val;

  $.fn.val = function(value) {
    if (typeof value != 'undefined') {
      // setter
      originalVal.call(this, value);

      var parent = $(this).parent();

      if (parent.hasClass('md-textfield') || parent.hasClass('md-select')) {
        mdFormHelper.handleMdFieldChange(this, value);
      } else if (parent.hasClass('md-choise') || parent.hasClass('md-fab-radio')) {
        mdFormHelper.handleMdChoiseFieldChange(this, value);
      }

      return $(this);
    } else {
      //getter
      return originalVal.call(this);
    }

  };

  // Multi Select
  $.fn.isMultiSelectAnswered = function() {
    if (!this || !this.length) return false;
    return $(this).find('.selected').length === $(this).find('.multi-select-column').length;
  };

  $.fn.updateMultiColumnSelectText = function(options) {
    this.each(function(_index, el){
      var resultBlock = $(el).find('.multi-column-select-result'),
          selected = $(el).find('.selected');

      var text = selected.map(function(){
        return $(this).html().trim();
      }).get().join(' | ');

      if (text){
       resultBlock.html(text);
      }
    })
  };

  $.fn.handleRegistrationRecordChange = function() {
    $(this).each(function(_index, block){
      var recordLine = $(block).parents('.md-records-outer').siblings('.md-records-line');

      if ($(block).find(".md-record").length) {
        recordLine.css({ display: 'flex' });
      } else {
        recordLine.hide();
      }
    });

    mdRegistrationSectionHandler.trackRecordsTableSize();
  };

  $.fn.removeMdErrors = function() {
    $(this).find(".md-error:not('.static-error')").remove();
    $(this).find('.is-invalid').removeClass('is-invalid');
    return $(this);
  };

  $.fn.resetMdFileField = function() {
    var mdFileDrop = $(".md-file-drop[data-target-field='#" + $(this).attr('id') + "']");
    mdFileDrop.find(".md-file-name").text('');
    mdFileDrop.find(".md-dropped-file").addClass('hidden');
    $(this).val('');
  };

  $.fn.resetMdFields = function() {
    $(this).find(mdFormHelper.selectors.text).val('').trigger('change');

    $(this).find(mdFormHelper.selectors.select).each(function(_index, select) {
      var firstVal = $(select).find('option:first').val();
      $(select).val(firstVal).trigger('change');
    });

    $(this).find("[type='file']").each(function(_index, input) {
      $(input).resetMdFileField();
    });

    $(this).find(mdFormHelper.selectors.choise).prop('checked', false).trigger('change');
    return $(this);
  };

  $.fn.disableMdFields = function() {
    $(this).each(function(_index, field){
      $(field).prop('disabled', true).parents('.md-textfield, .md-select').addClass('disabled');
    });
  };

  $.fn.enableMdFields = function() {
    $(this).each(function(_index, field){
      $(field).prop('disabled', false).parents('.md-textfield, .md-select').removeClass('disabled');
    });
  };

  $.fn.scrollToMakeVisible = function() {
    if ($(this).parents('.shadowed').length) { return; }

    var elementTop = $(this).offset().top,
        elementBottom = elementTop + $(this).outerHeight(),
        viewportTop = $(window).scrollTop(),
        viewportBottom = viewportTop + $(window).height();

    if (elementBottom > viewportBottom) {
      $(window).scrollTop(viewportTop + elementBottom - viewportBottom);
    }

    return $(this);
  };

  $.fn.highlightIfTargetHasError = function() {
    $(this).each(function(_index, field){
      var id = $(field).data('error-higlight-trigger');

      if (id) {
        $(this).toggleClass('is-invalid', !!$('#' + id).find('.md-error').length);
      }
    });
  };
})(jQuery);


mdFormHelper.activateMdFields = function (sourceBlock) {
  var fieldSelectors = mdFormHelper.selectors.text + ', ' + mdFormHelper.selectors.select;
  sourceBlock.find(fieldSelectors).toArray().forEach(function(el) {
    mdFormHelper.handleMdFieldChange(el, el.value);
    $(el).attr('tabindex', 0);
  });

  sourceBlock.find(mdFormHelper.selectors.choise).toArray().forEach(function(el) {
    mdFormHelper.handleMdChoiseFieldChange(el);
  });
};

$(function() {
  $(window).load(function(){
    setTimeout(function() {
      mdFormHelper.hideSpinner();
      mdFormHelper.windowIsLoaded = true;
    }, 300);
  });
});

document.addEventListener("turbo:render", function() {
  mdFormHelper.ajaxRequests = 0;

  if (mdFormHelper.windowIsLoaded) {
    mdFormHelper.hideSpinner();
  }
});

setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
  $('body').on('focusout', mdFormHelper.selectors.text, function() {
    mdFormHelper.handleMdFieldChange(this, this.value);
  });

  $('body').on('change', mdFormHelper.selectors.select, function() {
    mdFormHelper.handleMdFieldChange(this, this.value);
  });

  $('body').on('change', mdFormHelper.selectors.choise, function() {
    mdFormHelper.handleMdChoiseFieldChange(this);
  });

  $('.password-icon').on('click', function() {
    mdFormHelper.toggleMdPassword(this);
  });

  $('.md-form.html5-validation').on('submit', function() {
    return materialValidator.validateForm(this);
  });

  $('a[data-turbo-frame]').on('click', mdFormHelper.showSpinner);

  mdFormHelper.activateMdFields($('.md-form'));
});

document.addEventListener('turbo:frame-load', () => {
  mdFormHelper.hideSpinner();
});

document.addEventListener("turbo:before-fetch-request", function(){
  mdFormHelper.showSpinner();
});

document.addEventListener("turbo:frame-render", function(event) {
  mdFormHelper.activateMdFields($(event.target));
  mdFormHelper.hideSpinner();
});
