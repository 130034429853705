global.FacebookAuth = {
  //TODO: use where it is necessary in other functions of the object
  forConnectedUser: function(callback){
    FB.getLoginStatus(function(response) {
      if (response.status === 'connected') {
        callback(response);
      } else {
        FB.login(function(loginResponse) {
          callback(response);
        })
      }
    }, true);
  },

  fillNewUser: function(){
    FB.login(function(loginResponse) {
      FacebookAuth.initializeNewUser(loginResponse);
    }, { scope: 'email,user_birthday,user_gender'});
  },

  initializeNewUser: function(response){
    if (response.authResponse){
      var token = response.authResponse.accessToken;
      if (token)
        window.location = "/facebook_users/new?fb_token=" + token;
    }
  },

  signIn: function(response){
    FB.getLoginStatus(function(response) {
      if (response.status === 'connected') {
        FacebookAuth.tryLogin(response);
      } else {
        FB.login(function(loginResponse) {
          FacebookAuth.tryLogin(loginResponse);
        })
      }
    }, true);
  },

  tryLogin: function(response){
    var authResponse  = response.authResponse;
    if (authResponse){
      var token = authResponse.accessToken, id = authResponse.userID;
      if (token && id){
        $.ajax({
          type: "POST",
          url: "/facebook_users/login",
          data: { fb_token: token },
          success: function(data) {
            if (data.result == "success")
              window.location = data.url;
            else
              showForSelector("#facebook-login-form");
          },
          dataType: 'json',
          async: false
        });
      }
    }
  }
};
