global.TrainingLevelHandler = {
  cache: {},

  loadToField: function(selector, id, type){
    var constructionId = $('#construction_field_id').val();

    if (TrainingLevelHandler.cache[selector] && id && id != constructionId)
      return;

    var options, field = $(selector);

    if (id){
      if ( id == constructionId )
        options = $('#training_' + type + '_options').val();
      else
        options = $('#training_skill_options').val();
    }

    field.resetOptions(options);
    TrainingLevelHandler.cache[selector] = id && id != constructionId;
  }
}
