global.update_states = function(element_id, load_state) {
  load_state = typeof load_state !== 'undefined' ? load_state : false;
  var state_select = jQuery("#" + element_id.replace("country_id", "state"));
  var state = state_select.val();
  var stateRequiredAttr = state_select.prop('required') ? "required='required'" : "";
  var state_label = jQuery("label[for='" + element_id.replace("country_id", "state") + "']");
  var post_code_label = jQuery("label[for='" + element_id.replace("country_id", "post_code") + "']");
  var new_state_field;

  if (jQuery("#" + element_id + " option:selected").text() == 'United States of America') {
    state_label.html("State:");
    post_code_label.html("ZIP Code:");
    var new_state_select_options = state_select_options();
    new_state_field =  "<select id='" + state_select.prop('id') + "' name='" + state_select.prop('name') + "' class='" + state_select.prop('class') + "' " + stateRequiredAttr + "><option value=''>Please Select</option>" + new_state_select_options + "</select>";
    state_select.replaceWith(new_state_field);
    jQuery("#" + element_id.replace("country_id", "state")).val(state);

  } else {
    state_label.html("Province or Region:");
    post_code_label.html("Postal Code:");
    var state_value = load_state ? state : "";
    new_state_field = "<input id='" + state_select.prop('id') + "' name='" + state_select.prop('name') + "' " +
                              stateRequiredAttr + " class='" + state_select.prop('class') + "' value = '" + state_value + "' type='text'/>"
    state_select.replaceWith(new_state_field);
  }
}

global.handleAddressFormDisplaying = function (element_id){
  var countrySelect = $("#" + element_id),
      countryBlock = countrySelect.parents('.md-select'),
      postCodeLabel = $("label[for='" + element_id.replace("country_id", "post_code") + "']"),
      stateFields = countryBlock.parent().find('.address_state_fields'),
      regionFields = countryBlock.parent().find('.address_region_fields');

  if (countrySelect.find("option:selected").text() == 'United States of America') {
    stateFields.show().find('input, select').prop('disabled', false);
    regionFields.hide().find('input, select').prop('disabled', true);
    postCodeLabel.html("ZIP Code");
  } else {
    stateFields.hide().find('input, select').prop('disabled', true);
    regionFields.show().find('input, select').prop('disabled', false);
    postCodeLabel.html("Postal Code");
  }
}