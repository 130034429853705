global.showReferralForm = function (){
  if($("#referrals_form").length != 0){
    var facebox = $("#facebox");
    $.facebox($("#referrals_form").show());
    resetReferralErrors();
    facebox.css({position: 'fixed', top: '150px'});
  }
  animateMailServiceImages();
}

function animateMailServiceImages(){
  var image, imageWidth = $('#facebox .mail-service img').first().width();
  if (imageWidth){
    $('#facebox .mail-service').bind('mouseenter mouseleave', function(e) {
      $(this).find("img").stop().animate({
        width: imageWidth * (e.type === 'mouseenter' ? 1.07 : 1)
      }, 100);
    });
  }
}

global.popupInvoker = {
  referral_form_usage: function(){ showReferralForm() } ,
  referral_success_usage: function() { showReferralSuccessPopup("") },
  dashboard_usage: function(){ showFacebox('candidate_welcome_dashboard') },
  demographic_info_usage: function(){
    var content = $('#facebox_demographic_questionnaires').html();
    $.facebox(content, null, { closeLinkText: 'Skip This Step' });
  },
  facebook_assessment_info_usage: function(){
    FacebookPost.shareAssessmentCompletion();
  },
  behavioral_assessment_popup_usage: function(){ showFacebox('behavioral_assessment_popup') }
};

global.showDashboardPopups = function (){
    if (window.innerWidth <= 1024) return;

    var popup = findFaceboxByUrl() || $(".dashboard_popup[value=true]:first");

    if (popup.length != 0) {
      setTimeout(function(){
        popupInvoker[popup.prop("id")]();
        popup.val("false");
        bindDashboardFaceboxClose(popup.attr("rel"));
      }, 300)
    }
    return false;
}

global.showFacebox = function (id, faceboxClass, options){
  setTimeout(function(){
    var popupMessage = $('#' + id);

    if (popupMessage.length != 0){
      $.facebox(popupMessage.html(), faceboxClass, options);
    }
  }, 200);
}

global.showFaceboxForm = function (selector, action, options = {}){
  var popupContent = $(selector);

  if (popupContent.length) {
    var form = document.createElement('form');
    form.setAttribute('action', action);
    form.setAttribute('method', options.method || 'POST');

    if (options.html5Validation) {
      form.setAttribute('onsubmit', "materialValidator.validateForm(this, event)");
      form.setAttribute('onsubmit', options.onsubmit || "materialValidator.validateForm(this, event)");
      form.setAttribute('novalidate', "true");
    }

    form.innerHTML = popupContent.html();
    $.facebox(form, options.class, {
      target: selector, afterRevealCallback: options.afterRevealCallback
    });
  }
}

function findFaceboxByUrl(){
  if (location.href.match(/referrer=true/) && $("#referral_form_usage").val() != "false"){
    return $("#referral_form_usage");
  } else if (location.href.match(/referral_saving=true/) && $("#referral_success_usage").val() != "false") {
    return $("#referral_success_usage");
  }
}

function bindDashboardFaceboxClose(selector){
   var callback = function(){
      if (!selector || $("#" + selector).val() == "false")
        $(document).trigger('close.facebox');
      else
        showDashboardPopups();
   };

  $('.popup .close').
    off('click').
    on('click', function(){
      callback();
      return false;
    });

  $('body').
    off('click', '.popup .close').
    on('click', '.popup .close', function(){
      callback();
      return false;
    });
}

global.showForSelector = function (selector){
  var element = $(selector);
  if (element.length != 0) $.facebox(element.html());
}

function closeFacebox(){
  $(document).trigger('close.facebox');
}

setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
  $(document).bind('reveal.facebox', function() {
    var pageName = $('#facebox input[name*="page_name"]');
    if (pageName.length != 0){
      $.ajax({
        type: "POST",
        data: {page: pageName.val()},
        url: "/interface_usages",
        dataType: "json",
        async: false
      })
    }
    PopupDecorator.fixPopupPosition();
  });


  if (($(".dashboard_popup[value=true]")).length == 0){
    $('body').off('click').on('click', '#facebox .close', function(){
      $(document).trigger('close.facebox');
    });
  }
});
