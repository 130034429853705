function showOrderedErrors(options){
  var error_block = options.field ? $(options.field) : $(".ordered_error_messages"),
      error_fields = options.error_fields ? options.error_fields : ".fieldError, .bounded-error-field";
      title = $('#errors_list_title');
  error_block.find('ul').empty();
  var error_fields = $(error_fields).map(function(){
    if($(this).parent().css("display") != "none"){return $(this)}
  });
  if (error_fields.size() > 0){
    if (title.length != 0){
      var title_text = title.text();
      title.text(title_text.replace("{size}", error_fields.size()));
    }
    error_fields.each(function() {
      $('.ordered_error_messages ul').append("<li>" + $(this).text() + "</li>");
    })
    error_block.addClass("errorExplanation");
    error_block.prop("id", 'errorExplanation');
    error_block.css("display", "block");
  }
}

global.login_and_redirect_to_fb_app = function(params) {
  var path = '/users/link_user_accounts?fb_redirect=true',
      appData = JSON.stringify(params);
  if(params) path += "&app_data=" + appData;
  setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
    FB.login(function(response) {
      if (response.authResponse) {
        window.location = path;
      }
    }, { scope: 'email,user_birthday,user_gender'});
  });
};

// ---Error displaying--------
function highlightErrorsFields(){
  $(".bounded-error-field").each(function(){
    var wraper = $(this).parent();
    var parent = wraper.parent();
    wraper.height($(this).height() + "px");
    var errorField = $(this).attr("field");
    if(errorField) $("#" + errorField).addClass("error_input_border");
    wraper.parent().css("margin-bottom", "-5px")
    wraper.css("visibility", "visible");
  })
}

global.showErrors = function() {
  highlightErrorsFields();
  if ($(".fieldError").length != 0){
    var fields_for_errors = "select:not(:hidden), input:visible, textarea";
    $(".fieldError").each(function(){
      var error_value = $(this).val();
      var settings = setFromId(error_value, $(this));
      var setting_position  = settings[0];

      if (!setting_position){
        var siblings = $(this).siblings(fields_for_errors);
        if (siblings.length > 0 && siblings.length < 2){
          setting_position = addErrorClasses(siblings.first(), $(this), settings);
        }

        if (!setting_position){
          if (!setFromPrevElements($(this),settings))
            setFromAllSiblings(siblings, $(this), settings);
        }
      }
      $(this).addClass('visible');
      removeExtraSpaces($(this));
    })
  }
}

function setFromAllSiblings(siblings, element, settings) {
  var lefts = [];
  var objects = [];
  siblings.each(function(){
    lefts.push($(this).position().left);
    objects.push($(this));
  })

  if (lefts.length != 0){
    var left =  Math.min.apply(Math, lefts);
    var obj = objects[lefts.indexOf(left)];
    addErrorClasses(obj, element, settings);
  }
  else{
    var parent = element.parent();
    var parent_left = getFromParent($(this), parent, settings);
    if (parent_left != null){
      element.css("left", parent_left);
    }
  }
}

function addErrorClasses(field, message, settings){
  if (field.length != 0){
    var set_highlight = (Object.prototype.toString.call(settings) == "[object Array]") ?  settings[1] : false
    if (!set_highlight){
      field.addClass("error_input_border");
    }
    message.css("left", field.position().left);
    var lefts = [message.position().left];
    message.prevAll('.fieldError').each(function(){
      if ($(this).css("opacity") == 1)
        lefts.push($(this).position().left);
    })
    var left =  Math.min.apply(Math, lefts);
    message.css("left", left);
    return true;
  }else{
    return false;
  }
}

function removeExtraSpaces(element) {
  var prev_space = element.prev().prev();
  if (prev_space.hasClass('place_for_error'))
    prev_space.removeClass('place_for_error');
}

function setFromId(error_field, element){
  var setting_position = false;
  var setting_highlight= false;
  var element_by_id = $("#" + error_field);
  if (element_by_id.length != 0){
    setting_position = addErrorClasses(element_by_id, element);
    return [setting_position, setting_highlight]
  }
  if (error_field){
    var errors = error_field.split(";");
    var opt, i, j, obj, prefix;
    if (errors.length != 0){
      for(i = 0; i < errors.length; i++ ){
        opt = errors[i].split(":");
        if (opt[0] == 'highlight' || opt[0] == "highlight_class"){
          if (opt[1] != "none")
            {
              var ids = opt[1].split(',');
              for(j = 0; j < ids.length; j++ ){
                if (opt[0] == 'highlight')
                  prefix = "#";
                else
                  prefix = ".";
                obj = $(prefix + $.trim(ids[j]))
                if (obj.length != 0)
                  obj.addClass("error_input_border");
              }
            }
            setting_highlight = true;
        }
        if (opt[0] == 'position'){
          obj = $("#" + opt[1]);
          if (obj.length != 0){
            element.css("left", obj.position().left);
            setting_position = true;
          }
        }
      }
    }
  }
  return [setting_position, setting_highlight];
}

function setFromPrevElements(element, settings) {
  var fields_for_errors = 'select, input:visible, textarea'
  var prev_elements = element.prevAll(fields_for_errors);
  var setting_position = minFromElements(element, prev_elements, settings);
  if (!setting_position){
    var finded_in_prev = element.prevAll().find(fields_for_errors);
    if (finded_in_prev.length != 0)
      setting_position = minFromElements(element, finded_in_prev);
  }
  return setting_position;
}

function minFromElements(element, prev_elements, settings){
  if (prev_elements.length != 0){
    var min_obj = prev_elements.first();
    var min_left = min_obj.position().left;
    var prev_left;

    if (min_left){
      prev_elements.each(function(){
        prev_left = $(this).position().left;
        if (min_left > prev_left){
          min_left = prev_left;
          min_obj = $(this)
        }
      })
    }
    if (min_obj){
      addErrorClasses(min_obj, element, settings);
    }
  }
}

function getFromParent(element, parent){
  if (parent.length == 0)
    return null;

  var fields_for_errors = 'select, input:visible, textarea'
  var element = parent.is(fields_for_errors);
  if (element == true){
    return parent.position().left;
  }

  var elements = parent.siblings().find(fields_for_errors);
  if (elements.length != 0){
    var left_arr = [];
    elements.each(function(){
      left_arr.push($(this).position().left);
    })
    if (left_arr.length != 0){
     var left =  Math.min.apply(Math, left_arr);
     return left;
    }
  }
  else{
   return getFromParent(element, parent.prev());
  }
}

global.redirect_to_additional_info = function () {
  var email = $("#user_pending_registration_email").val();
  window.location = $("#additional_info_link").val() + "?email=" + email;
}

global.state_select_options = function () {
  // TODO: Use State object
  return "<option value='AL'>Alabama</option><option value='AK'>Alaska</option><option value='AZ'>Arizona</option><option value='AR'>Arkansas</option><option value='CA'>California</option><option value='CO'>Colorado</option><option value='CT'>Connecticut</option><option value='DC'>District of Columbia</option><option value='DE'>Delaware</option><option value='FL'>Florida</option><option value='GA'>Georgia</option><option value='HI'>Hawaii</option><option value='ID'>Idaho</option><option value='IL'>Illinois</option><option value='IN'>Indiana</option><option value='IA'>Iowa</option><option value='KS'>Kansas</option><option value='KY'>Kentucky</option><option value='LA'>Louisiana</option><option value='ME'>Maine</option><option value='MD'>Maryland</option><option value='MA'>Massachusetts</option><option value='MI'>Michigan</option><option value='MN'>Minnesota</option><option value='MS'>Mississippi</option><option value='MO'>Missouri</option><option value='MT'>Montana</option><option value='NE'>Nebraska</option><option value='NV'>Nevada</option><option value='NH'>New Hampshire</option><option value='NJ'>New Jersey</option><option value='NM'>New Mexico</option><option value='NY'>New York</option><option value='NC'>North Carolina</option><option value='ND'>North Dakota</option><option value='OH'>Ohio</option><option value='OK'>Oklahoma</option><option value='OR'>Oregon</option><option value='PA'>Pennsylvania</option><option value='RI'>Rhode Island</option><option value='SC'>South Carolina</option><option value='SD'>South Dakota</option><option value='TN'>Tennessee</option><option value='TX'>Texas</option><option value='UT'>Utah</option><option value='VT'>Vermont</option><option value='VA'>Virginia</option><option value='WA'>Washington</option><option value='WV'>West Virginia</option><option value='WI'>Wisconsin</option><option value='WY'>Wyoming</option>";
}

global.dismiss_notice = function (link) {
  $(link).parent().hide();
  if ($("#search_results").length > 0) {
      $.cookie('search_notice_closed', 'true', { path: '/' });
  }
  return false;
};

global.confirm_delete_object = function (id, text){
	var content = "<p>" + text +"</p>" + "<a class='facebox_right_bottom_button' href='#' onclick='$("+ '"#'+id+ '"'+ ").click(); return false;'>ok</a>"
	jQuery.facebox(content);
}

global.facebox_confirm = function (text, success_url){
	var content = "<p>" + text +"</p>" + "<a class='facebox_right_bottom_button' href='"+success_url+"'>ok</a>"
	jQuery.facebox(content);
}

function check_terms_of_service(){
  if ($("#user_terms_of_service").val() == 'false'){
    $.facebox($('#terms_of_service').show(), 'hide-close', { close: false });
  }
}

//Military Service
global.loadRanks = function () {
  var branch_select = $("#candidate_candidate_military_service_attributes_branch_id, .search_military_branch");
  displayHighestRankOrCareerPath();
  if (branch_select.length != 0){
    branch_select.change(function() {
      displayHighestRankOrCareerPath();
    });

    branch_select.ready(function() {
      displayHighestRankOrCareerPath();
    });
  }
};

function displayHighestRankOrCareerPath(){
  var branches_for_ranks = ["Army", "Marine Corps", "Navy", "Air Force", "Coast Guard"];
  var current_branch = $("#candidate_candidate_military_service_attributes_branch_id :selected, .search_military_branch :selected");
  var current_branch_text = current_branch.text();

  if ($.inArray(current_branch_text, branches_for_ranks) != -1){
    getRanks(current_branch.val());
    $("#career_path").hide();
    $("#highest_rank").show();
  }
  else{
    $("#highest_rank").hide();
    $("#highest_rank").find('select').html("<option></option>");
    if ( current_branch_text != "" ) {
      $("#career_path").show();
      $('#candidate_military_service_code_ids').select2({ closeOnSelect: false });
    }
  }
};

function getRanks(branch_id){
  $.ajax({
    type: "GET",
    url: "/candidate_military_services/load_ranks",
    data: { branch_id: branch_id },
    success: function(result){
      var select = $("#candidate_candidate_military_service_attributes_rank_id, .search_military_rank");
      if (select.length != 0){
        var options = $.map(result.ranks, function(i){
          return "<option value=" + i[1] + ">" + i[0] + "</option>";
        });
        select.html("");

        var blankOption = select.parents('.md-select').length ? '' : 'Please Select';
        select.append("<option value=''>" + blankOption + "</option>" + options.join(""));
      }

      var rank = $("#candidate_military_service_rank_id, .search_military_rank").val();
      $("#candidate_candidate_military_service_attributes_rank_id, .search_military_rank").val(rank);
    },
    dataType: 'json'
  });
};

function resizeHeader(){
  var header = $("#header-space");
  if (header.length !=0){
    header.css("height", "120px");
  }
};

global.fixFacebox = function (){
  $("#facebox:visible").css({position: "fixed"});
}

global.loadHelpPopup = function (url){
  $.get( url, null, function(json){ if(json && json.text) $.facebox(json.text); }, "json");
}

global.submitForm = function (element){
  $(element).parent().submit();
}

global.submitFormOnce = function (element){
  if (!$(element).attr('clicked')){
    $(element).attr('clicked', true);
    submitForm(element);
  }
}

global.preventInputEnter = function() {
  $(document).bind("keydown", function(event){
    if( event.which == 13 && $(event.target).is("input:text, input:password") ){
      event.preventDefault();
    }
    return true;
  });
}

global.disableFileLoading = function(name){
  var regex = new RegExp("(^| )" + name + "(,|$)"),
      result = $('.required_javascript').filter(function(){
        return regex.test(this.value);
      });

  return result.length == 0;
}

global.toggleVisibility = function (selector) {
  $(selector).toggle();
}

setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
  jQuery('a[rel*=facebox]').facebox();

  setTimeout(function() {
    showErrors();
    showOrderedErrors({});
  }, 300);
  jQuery("#unread>div").hide();
  jQuery(".value_box").on("click", function () {
    $(this).show_message_box();
  });
  check_terms_of_service();

  if ($.browser.msie){
    if ($.browser.version != "7.0"){
      $('input[placeholder], textarea[placeholder]').not('.not-ie-placeholder').placeholder();
    } else {
      resizeHeader();
    }
  }

  // Highlight field paragraphs

  $('body').on('focus', 'fieldset :input', function(event) {
    $(this).parents('p').addClass('focused');
  });

  $('body').on('blur', 'fieldset :input', function(event) {
    $(this).parents('p').removeClass('focused');
  });

  $('.select.all').on('click', function(event){
    $(this).parents('.content, .communication-section-content').find('input.row_check').prop('checked', true)
    $(this).parents('.content, .communication-section-content').find('input.row_check').parents('tr').addClass('selected');
    return false;
  });

  $('.unselect.all').on('click', function(event){
    $(this).parents('.content').find('input.row_check').prop('checked', false)
    $(this).parents('.content').find('input.row_check').parents('tr').removeClass('selected');
    return false;
  });

  $("table tr:nth-child(even)").addClass("even"); 

  $('.odd_and_even li:odd').addClass('odd');

  $('body').on('submit', ".login-box form", function(event){
    if ($(this).children("#email").val() == '' && $(this).children("#password").val() == ""){
      return false;
    }
  });

  $('body').on('change', ".terms_and_conditions_select", function(event){
    $("#selected_terms_of_service_val").val($(this).val());
    return false;
  });

  $("#landing_login_button").on("click", function() {
    $(".flash.notice").hide();
    $("#landing_login_button").hide(function(){
      $('.domain-login-block').addClass('invisible');
    });
    $("#landing_login_box").show(1000, function(){
      var headerLogin = $("#header .login-box #email:visible");
      if (headerLogin.length != 0) headerLogin.focus();
      else $(".login-box #email:visible").focus();
    });
  });

  $(document).bind('reveal.facebox', function() {
    var facebox = $("#facebox");
    if(facebox.length != 0) facebox.css({position: 'absolute'});
    PopupDecorator.resizeFixedPopup();
  });

  $('.run-javascript').each(function(){
    eval($(this).val());
  });

  $('.single_click').on('click', function(){
    if ( $(this).hasClass("unclickable") ) return false;
    else $(this).addClass("unclickable");
  });

  $('.acc-section').on('click', function() {
    $(this).toggleClass('active');
  });
});
