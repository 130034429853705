global.overlay = {};

overlay.show = function() {
  var popupOverlay = $('#popup_overlay');

  if (popupOverlay.length) {
    popupOverlay.attr("onclick", "sidenav.close();");
  } else {
    $("body").append('<div id="popup_overlay" class="popup_overlayBG" onclick="sidenav.close();"></div>');
  }
}

overlay.hide = function() {
  $('#popup_overlay').remove();
}
